import React from "react";

// MUI
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from "@mui/material";

// custom components
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
  const handleClose = () => {
    props.handleClose && props.handleClose();
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ zIndex: 9999999999999 }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "red" }}>
          {props.cancelBtnName || "Cancel"}
        </Button>
        <Button onClick={props.handleReject}>
          {props.loading ? (
            <CircularProgress />
          ) : (
            props.agreeBtnName || "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
