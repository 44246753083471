import { Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';
import Button from 'components/input/Button';
import React, { useContext, useState } from 'react'
import './EditStyle.css'
import { editCaptionFeatured } from './actions';
import { AlertContext } from 'contexts/AlertContext';

const CaptionEdit = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [captionText, setCaptionText] = useState(props.value);
  const [displayText, setDisplayText] = useState(props.value);
  const { showAlert } = useContext(AlertContext);

  const handleCaptionUpdate = async (caption) => {
    const { data, error } = await editCaptionFeatured(props.post_id, props.is_active, caption);
    if(data){
      showAlert("Updated Sucessfully!", "success");
      setDisplayText(captionText);
    }
    if(error){
      showAlert("Something Went Wrong!", "error");
    }
    setEditMode(false);
  };

  return (
    <div>
      {!editMode ? (
         <div
         className="language-display"
         onClick={() => {
             setEditMode(true);
         }}
     >
         {displayText}
         <Edit className="edit-icon" />
     </div>
 )
      :
           <>
            <TextField
                value={captionText}
                onChange={(e) => {
                  setCaptionText(e.target.value);
                }}
                label="Caption"
                variant="outlined"
                fullWidth
              />
              <Button variant="contained"
                color="primary" sx={{marginTop:"8px"}} onClick={()=>{
                  handleCaptionUpdate(captionText);
              }}>OK</Button>      
              </>
              }
    </div>
  )
}

export default CaptionEdit
