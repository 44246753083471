const styles = {
  customScrollbar: {
    scrollbarWidth: "thin",
    scrollbarColor: "#B7B7B7 transparent",
    padding: 0,
    margin: 0,
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      position: "absolute",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "30px",
      backgroundColor: "gray",
      minHeight: "60px",
      minWidth: 4,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
    "& .MuiTableRow-root": {
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
      "&:hover": { background: "#D1E6FF" },
    },
  },
  loadingTableCell: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "250px",
  },
  noDataSection: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
    width: "100%",
    color: "gray",
    height: "250px",
    position: "relative",
  },
  noDataSectionImage: {
    width: "300px",
    height: "250px",
    margin: "auto",
    left: "40%",
    position: "absolute",
    top: 0,
  },
};

export default styles;
