import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { getContentSupply, getStatewiseWeekly } from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";

const columns = [
  {
    id: "state_name",
    label: "State Name",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total_signups",
    label: "Total Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "android",
    label: "Android",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "ios",
    label: "iOS",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "unknown",
    label: "Unknown",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "current_signups",
    label: "Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "current_wau",
    label: "Weekly Active Users",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "last_week_signups",
    label: "Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "last_week_wau",
    label: "Weekly Active Users",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  state_name,
  total_signups=0,
  android=0,
  ios=0,
unknown=0,
current_signups=0,
  current_wau=0,
  last_week_signups=0,
  last_week_wau=0,

  
) {
  return {
    state_name,
  total_signups,
  android,
  ios,
unknown,
current_signups,
  current_wau,
  last_week_signups,
  last_week_wau
  };
}

//let rows = [];

export default function StateWiseWeekly() {
  const [page, setPage] = React.useState(0);
  const [table_date1, setTableDate1] = useState("");
  const [table_date2, setTableDate2] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(37);
  const [date, setDate] = useState([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getStatewiseWeeklyData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getStatewiseWeeklyData = async (
    date,
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getStatewiseWeekly(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
       let rowData = [];
        data?.data?.forEach((tablerow, index) => {
          console.log(tablerow);
          setTableDate1(formatReadableDate(data.dates.date_from)+ " - " + formatReadableDate(data.dates.date_to))
          setTableDate2(formatReadableDate(data.dates.previous_date_from)+ " - " + formatReadableDate(data.dates.prev_date_to))
          rowData.push(
            createData(
              Object.keys(data.data[index]).toString(),
              tablerow[Object.keys(tablerow)].android+
              tablerow[Object.keys(tablerow)].ios+
              tablerow[Object.keys(tablerow)].others,
              tablerow[Object.keys(tablerow)].android,
              tablerow[Object.keys(tablerow)].ios,
              tablerow[Object.keys(tablerow)].others,
              tablerow[Object.keys(tablerow)].date_range_signups,
              tablerow[Object.keys(tablerow)].active_users,
              tablerow[Object.keys(tablerow)].prev_date_range_signups,              
              tablerow[Object.keys(tablerow)].previous_active_users,
        
            
            )
          );
        });
        setRows(rowData);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getStatewiseWeeklyData([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return   rows.length === 0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
  
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>State-wise Engagement (Weekly)</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />
          <Button
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow sx={{ }}>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'}}
                   colSpan={2}   />
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={3}>
                Source
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={2}>
               {table_date1}
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={2}>
                {table_date2}
              </TableCell>
           
            </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ backgroundColor: "rgba(209, 230, 255, 1)" , borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
