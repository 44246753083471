import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { getAcquisitionReport, getContentSupply } from "../actions";
import { useEffect } from "react";
import JsPDF from 'jspdf';
import Loading from "../loader/LoadingAnim";

const columns = [
  {
    id: "week",
    label: "Week",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "total_signups",
    label: "Total Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "android_website",
    label: "Website",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "android_organic",
    label: "Organic",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "android_shares",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "android_referrals",
    label: "Referrals",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "ios_website",
    label: "Website",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "ios_organic",
    label: "Organic",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "ios_shares",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "ios_referrals",
    label: "Referrals",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "others_website",
    label: "Website",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "others_organic",
    label: "Organic",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "others_shares",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "others_referrals",
    label: "Referrals",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },


];

const generatePDF = () => {

  const report = new JsPDF('portrait','pt','a4');
  report.html(document.querySelector('#acquisitions_report')).then(() => {
      report.save('acquisitions_report.pdf');
  });}

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  week,
  total_signups=0,
  android_website=0,
  android_organic=0,
  android_shares=0,
  android_referrals=0,
  ios_website=0,
  ios_organic=0,
  ios_shares=0,
  ios_referrals=0,
  others_website=0,
  others_organic=0,
  others_shares=0,
  others_referrals=0,
  
) {
  return {
    week,
    total_signups,
    android_website,
    android_organic,
    android_shares,
    android_referrals,
    ios_website,
    ios_organic,
    ios_shares,
    ios_referrals,
    others_website,
    others_organic,
    others_shares,
    others_referrals,
  };
}



export default function AcquisitionsReport() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [date, setDate] = useState([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
  const [rows, setRow] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRow([]);
      console.log(formatDate(value));
      setDate(value);
      getAcquisitionReportData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getAcquisitionReportData = async (
    date,
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getAcquisitionReport(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRow([]);
      } else {
        let rowTemp = [];
        data?.countData?.forEach((tablerow, index) => {
          console.log(tablerow);
          rowTemp.push(
            createData(
              formatReadableDate(tablerow.date_from) +
                " - " +
                formatReadableDate(tablerow.date_to),

               ( tablerow.android.website+
                tablerow.android.organic+
                tablerow.android.share+
                tablerow.android.referral+
                       
                tablerow.ios.website+
                tablerow.ios.organic+
                tablerow.ios.share+
                tablerow.ios.referral+
  
                tablerow.others.website+
                tablerow.others.organic+
                tablerow.others.share+
                tablerow.others.referral),
              
              tablerow.android.website,
              tablerow.android.organic,
              tablerow.android.share,
              tablerow.android.referral,
                     
              tablerow.ios.website,
              tablerow.ios.organic,
              tablerow.ios.share,
              tablerow.ios.referral,

              tablerow.others.website,
              tablerow.others.organic,
              tablerow.others.share,
              tablerow.others.referral,

              
            )
          );
        });
        setRow(rowTemp);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getAcquisitionReportData([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return rows.length === 0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
  
   
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Acquisitions Report</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button
         
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <div id = 'acquisitions_report'>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow sx={{ }}>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'}}
               colSpan={2}      />
      
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={4}>
                Android (Signups)
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)',borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={4}>
                iOS (Signups)
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={4}>
                Others
              </TableCell>
           
            </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ backgroundColor: "rgba(209, 230, 255, 1)" , borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          </div>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
