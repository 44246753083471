import React, { useContext } from "react";

// components
import AlertBar from "components/AlertBar";
import Sidebar from "components/sidebar";

// contexts
import { AuthContext } from "contexts/AuthContext";

// styles
import styles from "../../styles";

// MUI
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTags, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";

const sidebarOptions = [
  {
    title: "Analytics",
    link: "/admin/analytics",
    IconComponent: <FontAwesomeIcon icon={faChartSimple} size="xl" />,
    isProtected: false,
  },
  {

    title: "Content Tagging",
    link: "/admin/tagging",
    IconComponent: <FontAwesomeIcon icon={faTags} size="xl" />,
    isProtected: true,
  },
  {
    title: "Tags Verification",
    link: "/admin/tag-verification",
    IconComponent: (
      <TaskAltOutlinedIcon sx={{ height: "1.6em !important", width: "1em" }} />
    ),
    isProtected: true,
  },

  {
    title: "Upload Content",
    link: "/admin/upload-content",
    IconComponent: (
      <FontAwesomeIcon icon={faVideo} size="xl" />
    ),
    isProtected: true,
  },

  {
    title: "Notification",
    link: "/admin/notification",
    IconComponent: (
      <FontAwesomeIcon icon={faBell} size="xl" />
    ),
    isProtected: false,
  },
 
];

const Base = ({ classes, children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Box className={classes.root}>
      {isLoggedIn && (
        <Box className={classes.sidebar}>
          <Sidebar sidebarOptions={sidebarOptions} />
        </Box>
      )}
      <Box className={`${isLoggedIn ? classes.authBody : classes.nonAuthBody}`}>
        <main className={classes.body}>
          {children}
          <AlertBar />
        </main>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Base);
