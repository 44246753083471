import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { downloadStatewiseEngagementReport, getContentSupply, getStatewiseDaily } from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";
// import './StatewiseDaily.css';

const columns = [
  {
    id: "date",
    label: "Date",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state_name",
    label: "State Name",
    minWidth: 180,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total_signups",
    label: "Total Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "daily_active_users",
    label: "Daily Active Users",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  // {
  //   id: "avg_eng_time",
  //   label: "Avg Engagement Time",
  //   minWidth: 60,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },

  {
    id: "avg_content",
    label: "Avg No. of Content Consumed per User",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "website",
    label: "Website",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "organic",
    label: "Organic",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "shares",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "referrals",
    label: "Referrals",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "views",
    label: "Views",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "share",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "downloads",
    label: "Downloads",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "gen_content",
    label: "No of Generated Content",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "returning_users",
    label: "Returning Users",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "seven_days",
    label: "1-7 Days",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirty_days",
    label: "7-30 Days",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirty_plus_days",
    label: "30+ Days",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  date,
  state_name = 0,
  total_signups = 0,
  daily_active_users = 0,
  // avg_eng_time=0,
  avg_content = 0,
  website = 0,
  organic = 0,
  shares = 0,
  referrals = 0,
  views = 0,
  likes = 0,
  share = 0,
  downloads = 0,
  gen_content = 0,
  returning_users = 0,
  seven_days = 0,
  thirty_days = 0,
  thirty_plus_days = 0
) {
  return {
    date,
    state_name,
    total_signups,
    daily_active_users,
    // avg_eng_time,
    avg_content,
    website,
    organic,
    shares,
    referrals,
    views,
    likes,
    share,
    downloads,
    gen_content,
    returning_users,
    seven_days,
    thirty_days,
    thirty_plus_days,
  };
}

const downloadCSV = async (
  date
) => {
  try {
 // setIsLoading(true);
    const formattedDate = formatDate(date);
    const { data, error } = await downloadStatewiseEngagementReport(
      formattedDate.startDate,
      formattedDate.endDate
    );
  //  console.log(data);  
     
  } catch (error) {
    console.log("Error", error.message);
   // setIsLoading(false)
  }
};

export default function StateWiseDaily() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(38);
  const [date, setDate] = useState([
    moment().subtract(1, "d")._d,
    moment().subtract(1, "d")._d,
  ]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getStatewiseDailyData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getStatewiseDailyData = async (
    date,
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getStatewiseDaily(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
        let rowData = [];
        data?.data?.forEach((tablerow, index) => {
          console.log(tablerow);
          rowData.push(
            createData(
              formatReadableDate(formattedDate.startDate),
              Object.keys(tablerow).toString(),

              tablerow[Object.keys(tablerow).toString()].date_range_signups,
              tablerow[Object.keys(tablerow).toString()]
                .average_daily_active_users,

              tablerow[Object.keys(tablerow)]
                .average_content_consumed_per_user_daily,

              tablerow[Object.keys(tablerow)].acquisition.website,
              tablerow[Object.keys(tablerow)].acquisition.organic,
              tablerow[Object.keys(tablerow)].acquisition.share,
              tablerow[Object.keys(tablerow)].acquisition.referral,

              tablerow[Object.keys(tablerow)].engagements["VIEW"],
              tablerow[Object.keys(tablerow)].engagements["LIKE"],
              tablerow[Object.keys(tablerow)].engagements["SHARE"],
              tablerow[Object.keys(tablerow)].engagements["DOWNLOAD"],

              tablerow[Object.keys(tablerow)].personalised_counts,
              tablerow[Object.keys(tablerow)]?.average_returning_users,

              tablerow[Object.keys(tablerow)].retention["1_7_days"],
              tablerow[Object.keys(tablerow)].retention["8_30_days"],
              tablerow[Object.keys(tablerow)].retention["more_than_30_days"]
            )
          );
        });
        rowData.push(
          createData(
            "",
            "Total",

            data.total.date_range_signups,
            data.total.average_daily_active_users,

            data.total.average_content_consumed_per_user_daily,

            data.total.acquisition.website,
            data.total.acquisition.organic,
            data.total.acquisition.share,
            data.total.acquisition.referral,

            data.total.engagements["VIEW"],
            data.total.engagements["LIKE"],
            data.total.engagements["SHARE"],
            data.total.engagements["DOWNLOAD"],

            data.total.personalised_counts,
            data.total?.average_returning_users,

            data.total.retention["1_7_days"],
            data.total.retention["8_30_days"],
            data.total.retention["more_than_30_days"]
          )
        );
        setRows(rowData);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getStatewiseDailyData([
        moment().subtract(1, "d")._d,
        moment().subtract(1, "d")._d,
      ]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return rows.length === 0 ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>
  ) : (
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>State-wise Engagement</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button onClick={()=>{downloadCSV(date)}}
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{}}>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  }}
                  colSpan={5}
                />
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  }}
                  colSpan={4}
                >
                  Acquisition
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  }}
                  colSpan={4}
                >
                  Engagement (Normal + Generated)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                  }}
                  colSpan={3}
                >
                  Returning User's Signup Age
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{
                      backgroundColor: "rgba(209, 230, 255, 1)",
                      borderRight:
                        index === 16 ? "none" : "1px solid rgba(0, 0, 0, 0.15)",
                    }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderRight:
                                index === 16
                                  ? "none"
                                  : "1px solid rgba(0, 0, 0, 0.15)",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
