import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import './EditStyle.css'
import { Edit } from "@mui/icons-material";
import { AlertContext } from "contexts/AlertContext";
import { editCaptionFeatured } from "./actions";

const SelectCategory = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [pageId, setPageID] = useState(-1);
  const [pageName, setPageName] = useState(props.value);
  const [pageList, setPageList] = useState(props.pageList);
  
  const { showAlert } = useContext(AlertContext);
  const handlePageUpdate = async (page_id) => {
    const { data, error } = await editCaptionFeatured(props.post_id, props.is_active, undefined, page_id);
    if(data){
      showAlert("Updated Sucessfully!", "success");
      for( let i = 0; i< pageList.length; i++){
        if(pageList[i].page_id === page_id){
            setPageName(pageList[i].page_name)
        }
    }
    }
    if(error){
      showAlert("Something Went Wrong!", "error");
    }
    setEditMode(false);
  };

  useEffect(() => {}, [editMode]);
  return (
    <>
      {!editMode ? (
         <div
         className="language-display"
         onClick={() => {
             setEditMode(true);
         }}
     >
         {pageName}
         <Edit className="edit-icon" />
     </div>
 ) : (
        <FormControl>
             <InputLabel>Page ID</InputLabel>
        <Select
          value={pageId}
          onChange={(e) => {
            setPageID(e.target.value);
            handlePageUpdate(e.target.value)
          }}
          label="Page ID"
        >
          <MenuItem key={-1} value={-1}>
            Select Page
          </MenuItem>
          {pageList.map((page, index) => {
              return (
                <MenuItem key={page.page_id} value={page.page_id}>
                  {page.page_name}
                </MenuItem>
              );
            
          })}
        </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectCategory;
