// packages
import AXIOS from "services/axios";


// utils
import { getConfig } from "utils/config";

export const getStateListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/states/admin_states/`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};


export const getUserCount = async (
  start_date,
  end_date, state_id, bucket_id
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.get(
     (state_id===""?`v1/get_all_users_in_bucket/?start_date=${start_date}&end_date=${end_date}&bucket_id=${bucket_id}`:`v1/get_all_users_in_bucket/?start_date=${start_date}&end_date=${end_date}&bucket_id=${bucket_id}&state_id=${state_id}`),
      config
      );
      response.data = data;
    } catch (error) {
      response.error = error.response.data.error;
    }
    return response;
  };

export const getBucketListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/notification/buckets`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const sendNotificationAPI = async (title, body, image, state_id, scheduling, bucket_id, start_date, end_date, additional_fields, numbers, userids) => {
  const response = { data: null, error: null };
const additional = (numbers.length!==0 && userids.length !==0) ? {
  "mobile": numbers,
  "user_id": userids
}: (numbers.length===0 && userids.length ===0) ? "": 
numbers.length===0? {"user_id": userids} : userids.length ===0?{ "mobile": numbers??[]} :"";
  const obj =additional !==""? {
    title,
    body,
    image,
    scheduling,
    bucket_id,
    start_date,
    end_date,
    additional_fields,
    additional
    } : {
    title,
    body,
    image,
    scheduling,
    bucket_id,
    start_date,
    end_date,
    additional_fields
  }

  console.log(obj);
  try {
   
     const { data } = await AXIOS.post(`v1/send_notification`, 
    state_id === -1 ?( additional !==""? {
      title,
      body,
      image,
      scheduling,
      bucket_id,
      start_date,
      end_date,
      additional_fields,
      additional
      } :  {
        title,
        body,
        image,
        scheduling,
        bucket_id,
        start_date,
        end_date,
        additional_fields
        
        })
      
    : (additional !==""? {
      title,
      body,
      image,
      scheduling,
      state_id,
      bucket_id,
      start_date,
      end_date,
      additional_fields,
      additional
      } :  {
      title,
      body,
      image,
      scheduling,
    state_id,
      bucket_id,
      start_date,
      end_date,
      additional_fields
      
      
    }));
   response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};


export const sendNotificationScheduledAPI = async (title, body, image, state_id, scheduling, bucket_id, start_date, end_date, additional_fields, numbers, userids, scheduled_time) => {
  const response = { data: null, error: null };
const additional = (numbers.length!==0 && userids.length !==0) ? {
  "mobile": numbers,
  "user_id": userids
}: (numbers.length===0 && userids.length ===0) ? "": 
numbers.length===0? {"user_id": userids} : userids.length ===0?{ "mobile": numbers??[]} :"";
 
  try {
   
     const { data } = await AXIOS.post(`v1/send_notification`, 
    state_id === -1 ?( additional !==""? {
      title,
      body,
      image,
      scheduling,
      bucket_id,
      start_date,
      end_date,
      scheduled_time,
      additional_fields,
      additional
      } :  {
        title,
        body,
        image,
        scheduling,
        bucket_id,
        start_date,
        end_date,
        scheduled_time,
        additional_fields
        
        })
      
    : (additional !==""? {
      title,
      body,
      image,
      scheduling,
      state_id,
      bucket_id,
      scheduled_time,
      start_date,
      end_date,
      additional_fields,
      additional
      } :  {
      title,
      body,
      image,
      scheduling,
      scheduled_time,
      state_id,
      bucket_id,
      start_date,
      end_date,
      additional_fields
      
      
    }));
   response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getNotificationHistory = async (
  
  limit,
  offset,
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(
      `v1/notification/history?limit=${limit}&offset=${offset}&notification_type=campaign`,
      config
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};



