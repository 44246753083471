const styles = {
  sidenav: {
    height: "100%",
    width: "5%",
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    background: "#2a2d32",
    overflowX: "hidden",
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: "20px",
    "@media (max-width: 1400px)": {
      width: "5%",
    },
    "@media (max-width: 1199px)": {
      width: "5%",
    },
  },
  navbarTop: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "132px",
    paddingLeft: "3px",
    width: "100%",
  },
  navbarLogo: {
    background: "#253766",
    borderRadius: "30px",
    width: "44px",
    height: "44px",
    color: "#FFF",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.02em",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center !important",
    // marginRight: "10px",
  },
  navBarTitle: {
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
    width: "54%",
    display: "flex",
    flexWrap: "wrap",
  },
  sidebarOptionsSection: {
    padding: "8px",
  },
  sidebarOption: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    minHeight: "35px",
    width: "98%",
    background: "transparent",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
    marginBottom: "26px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    paddingLeft: "5px",
  },
  activeSideOption: {
    transition: "all 0.3s ease-in-out",
    background: "#E6F2FF",
    color: "#2a2d32",
    fontWeight: 700,
  },
  iconComponent: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  websiteLogo: {
    width: "83%",
    height: "83%",
    margin: "0 auto",
  },
};

export default styles;
