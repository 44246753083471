import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "contexts/AuthContext";
import { AlertProvider } from "contexts/AlertContext";
import CSSThemeProvider from "ThemeProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CSSThemeProvider>
    <AuthProvider>
      <AlertProvider>
        <App />
      </AlertProvider>
    </AuthProvider>
  </CSSThemeProvider>
);
