import React from "react";

// MUI
import { Button, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

// styles
import styles from "./styles";

const PostStatusButton = (props) => {
  const { classes, option } = props;
  const { value, title, IconComponent, count } = option;
  return (
    <Button
      variant={props.postType === value ? "contained" : "outlined"}
      className={`${classes.topbarButton} ${
        props.postType !== value ? classes[`normalButton${title}`] : ""
      } ${
        props.postType === value ? `${classes[`selectedStatus${title}`]}` : ""
      }`}
      onClick={() => {
        props.selectPostType(value);
      }}
      sx={{
        height: "40px",
      }}
    >
      <IconComponent
        className={`${classes.topbarButtonIcon} ${
          props.postType !== value && classes[`icon${title}`]
        } ${props.postType === value && `${classes.iconSelected}`}`}
      />
      <Typography>
        {title} ({count})
      </Typography>
    </Button>
  );
};

export default withStyles(styles)(PostStatusButton);
