import React, { useEffect, useState } from "react";

// MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// styles
import styles from "../styles";
import TableComponent from "components/TableComponent";

// custom component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const infoColumns = [
  { name: "url", label: "Url", minWidth: "40%", align: "left" },
  { name: "text", label: "Text", minWidth: "60%", align: "left" },
];
export const InfoHeaderComponent = () => {
  return (
    <TableRow sx={{ backgroundColor: "#E7F2FF" }}>
      {infoColumns.map((column, index) => (
        <TableCell
          key={index}
          align={column.align}
          sx={{
            minWidth: column.minWidth,
            backgroundColor: "#F2F7FF",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
};

export const InfoBodyComponent = ({ rows }) => {
  return (
    <>
      {rows.map((row, index) => {
        if (!row.url || !row.text) {
          return null;
        }
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            {infoColumns.map((column, index) => {
              return (
                <TableCell
                  key={index}
                  align={column.align}
                  sx={{
                    width: column.minWidth,
                    borderRight:
                      index < infoColumns.length - 1
                        ? "1px solid rgba(224, 224, 224, 1)"
                        : "none",
                  }}
                  onClick={() => {
                    if (column.name === "url") {
                      window.open(row.url, "_blank");
                    }
                  }}
                >
                  <Box sx={{ color: column.name === "url" ? "#2D9CDB" : "" }}>
                    {column.name === "url"
                      ? `${
                          row[column.name]?.length > 25
                            ? row[column.name].slice(0, 25) + "..."
                            : row[column.name]
                        }`
                      : row[column.name]}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

const InforCard = ({ classes, show, setShow, data, setInfoData }) => {
  const [infoRows, setInfoRows] = useState([]);

  const setDataOnLoad = () => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        rows.push({
          url: data[i][j].post_source_url,
          text: data[i][j].caption,
        });
      }
    }

    setInfoRows(rows);
  };

  const handleClose = () => {
    setInfoData([]);
    setShow(false);
  };

  useEffect(() => {
    setDataOnLoad();
  }, [data]);

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ zIndex: 9999999999999 }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 24px",
        }}
      >
        <Box className={classes.dialogTitle}>Tag Verification/Modi Ji</Box>
        <Box>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContentSection}>
        <Paper sx={{ height: "441px", overflow: "hidden" }}>
          <TableComponent
            TableHeaderComponent={InfoHeaderComponent}
            TableBodyComponent={InfoBodyComponent}
            rows={infoRows}
            columns={infoColumns}
            pagination={false}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(InforCard);
