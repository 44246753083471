const useLocalStorage = () => {
  const getLocalStorage = (name) => {
    const value = localStorage.getItem(name) || null;
    return value;
  };
  const setLocalStorage = (name, value) => {
    localStorage.setItem(name, value);
  };

  const removeLocalStorage = (name) => {
    localStorage.removeItem(name);
  };
 
  return { getLocalStorage, setLocalStorage, removeLocalStorage };
};

export default useLocalStorage;
