import React, { useState, useEffect } from "react";

// MUI
import { Checkbox, Box } from "@mui/material";
import { withStyles } from "@mui/styles";

// styles
import styles from "../styles";

const TagStatus = ({
  classes,
  rowId,
  isApproved,
  handleApprovedReject,
  postType,
}) => {
  const [isActive, setIsActive] = useState(null);

  const updateTagsStatus = (status) => {
    if (status === isActive) {
      setIsActive(null);
    } else {
      setIsActive(status);
    }
  };

  useEffect(() => {
    setIsActive(isApproved);
  }, []);

  return (
    <Box
      className={`${classes.displayFlex} ${
        postType === "Pending" ? classes.spaceBtwn : classes.center
      }`}
    >
      {postType !== "Approved" && (
        <Box
          className={
            postType !== "Approved" ? classes.width50 : classes.width100
          }
          sx={{
            "& span": {
              color: "#2a2d32 !important",
            },
          }}
        >
          <Checkbox
            checked={isActive !== null && isActive}
            onClick={() => {
              handleApprovedReject(rowId, isActive ? null : true);
              updateTagsStatus(true);
            }}
            sx={{
              padding: 0,
              margin: 0,
              "& .css-uaptqc-MuiButtonBase-root-MuiCheckbox-root": {
                color: "#2a2d32 !important",
              },
            }}
          />{" "}
          Approve
        </Box>
      )}
      {postType !== "Rejected" && (
        <Box
          className={
            postType !== "Rejected" ? classes.width50 : classes.width100
          }
          sx={{
            "& span": {
              color: "#2a2d32 !important",
            },
          }}
        >
          <Checkbox
            checked={isActive !== null && isActive === false}
            onClick={() => {
              handleApprovedReject(
                rowId,
                isActive !== null && !isActive ? null : false
              );
              updateTagsStatus(false);
            }}
            sx={{ padding: 0, margin: 0 }}
          />{" "}
          Reject
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(TagStatus);
