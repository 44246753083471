// packages
import AXIOS from "services/axios";

// utils
import { getConfig } from "utils/config";

export const getStateListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/states/list/`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getLangListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/languages/list`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getBucketListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/get_bucket_list`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getAdminBucketListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/buckets/get_admin_bucket`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getPageListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/page/get_pages_for_feature`, {
      config,
    });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const uploadDataAPI = async (
  url,
  caption,
  page_id,
  language_id,
  thumbnail_url,
  bucket_id,
  state_id
) => {
  const response = { data: null, error: null };

  const token = localStorage.getItem("token") || null;
  const config = await getConfig();

  const formData = new FormData();
  formData.append("url", url);
  formData.append("caption", caption);
  formData.append("page_id", page_id);
  formData.append("language_id", language_id);
  formData.append("thumbnail_image", thumbnail_url);
  formData.append("bucket_id", bucket_id);
  formData.append("state_id", state_id);
  try {
    const { data, error } = await AXIOS.post(
      `v1/post/upload_feature_data`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getUploadHistory = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(
      `v1/post/get_latest_posts/?limit=100&offset=0`,
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const editCaptionFeatured = async (
  post_id,
  is_active,
  caption,
  page_id,
  language_id,
  state_id
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.put(
      `v1/posts/edit_uploaded_post`,
      { post_id, is_active, caption, page_id, language_id, state_id },
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const editBucket = async (
  bucket_id,
  is_active,
  bucket_name,
  state_ids
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.put(
      `v1/buckets/edit_bucket`,
      { bucket_id, is_active, bucket_name, state_ids },
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const addBucket = async (
  bucket_name,
  state_ids
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.post(
      `v1/buckets/add_bucket`,
      { bucket_name, state_ids },
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};
