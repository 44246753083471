import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";

const NotificationSidebar = (props) => {
  const { report, handleOptionClick } = props;
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div style={{ position: "relative" }}>

      {showSidebar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop:"100px",
            height: "98.5vh",
            width:"16vw",
            overflowY:'auto',
            minWidth: "25%",
            marginRight: "2rem",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid rgba(209, 230, 255, 1)",
          }}
        >
         <div style={{height:"20px"}}></div>

          <div
                id={"1"}
                onClick={() => {
                  handleOptionClick("1");
                }}
                style={{
                    display:"flex",
                    gap:"10px",
                    alignItems:"center",
                  color:
                    report === "1" ? "rgba(248, 65, 67, 1)" : "rgba(79, 79, 79, 1)",
                  fontWeight: report === "1" ? "bold" : "normal",
                  paddingBottom: "12px",
                  marginBottom: "15px",
                  cursor: "pointer",
                  borderBottom:
                    report === "1"
                      ? "1px solid rgba(248, 65, 67, 1)"
                      : "1px solid rgba(229, 229, 229, 1)",
                }}
              >
           <EditNotificationsIcon/>    Notification
              </div>

              <div
                id={"2"}
                onClick={() => {
                  handleOptionClick("2");
                }}
                style={{
                    display:"flex",
                    gap:"10px",
                    alignItems:"center",
                  color:
                    report === "2" ? "rgba(248, 65, 67, 1)" : "rgba(79, 79, 79, 1)",
                  fontWeight: report === "2" ? "bold" : "normal",
                  paddingBottom: "12px",
                  marginBottom: "15px",
                  cursor: "pointer",
                  borderBottom:
                    report === "2"
                      ? "1px solid rgba(248, 65, 67, 1)"
                      : "1px solid rgba(229, 229, 229, 1)",
                }}
              >
             <CloudOutlinedIcon/>  History
              </div>


   

    
        </Box>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NotificationSidebar;
