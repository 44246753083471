const styles = {
  skeletonBody: {
    margin: "auto",
    // marginTop: "125px",
    width: "98%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    border: "1px solid #E7F2FF",
    minHeight: "400px",
    maxHeight: "573px",
    position: "absolute",
    top: "15px",
    padding: "10px 15px 10px 15px",
    justifyContent: "space-around",
  },
  skeletonLeftSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  skeletonImage: {
    width: "23%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  skeletonRightSection: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  skeletonRightBottom: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    right: 0,
  },
  skeletonRightBottomInner: {
    display: "flex",
    justifyContent: "space-between",
    width: "35%",
  },
};

export default styles;
