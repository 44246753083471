import React from "react";

// MUI
import { withStyles } from "@mui/styles";
import { Box, style } from "@mui/system";

// styles
import styles from "./styles";

const PostImageVideoType = (props) => {
  const { classes } = props;
  return (
    <Box className={classes.optionsSection}>
      {props.options.map((option, index) => {
        return (
          <div
            key={index}
            className={classes.optionClass}
            style={{
              background:
                option.value === props.contentType ? "#2a2d32" : "transparent",
              color: option.value === props.contentType ? "#FFF" : "#2a2d32",
              fontWeight: 400,
            }}
            onClick={() => props.changeContentType(option.value)}
          >
            {option.title}
          </div>
        );
      })}
    </Box>
  );
};

export default withStyles(styles)(PostImageVideoType);
