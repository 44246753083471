const styles = {
  optionsSection: {
    width: 452,
    height: 42,
    background: "#FFFFFF",
    border: "1px solid #2a2d32",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    transition: "all 0.3s ease-in",
  },
  optionClass: {
    width: 214,
    height: 36,
    left: 209,
    top: 156,
    borderRadius: 30,
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center !important",
    cursor: "pointer",
    transition: "all 0.4s ease-in-out",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "19px",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
  },
};

export default styles;
