import React from "react";

// MUI
import { withStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";

// packages
import { Link } from "react-router-dom";

// styles 
import styles from "./styles";

const SidebarOption = (props) => {
  const { classes, activePage, option } = props;
  const CustomTooltip = withStyles({
    tooltip: {
      color: "#FFF !important",
      background: "rgba(0, 0, 0, 0.6) !important",
      fontSize: "1rem !important",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",
      backdropFilter: "blur(17.6px) !important",
      "-webkit-backdrop-filter": "blur(17.6px) !important",
      "& .MuiTooltip-arrow::before": {
        background: "rgba(0, 0, 0, 0.6) !important",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",
        backdropFilter: "blur(7.6px) !important",
        "-webkit-backdrop-filter": "blur(7.6px) !important",
      },
    },
  })(Tooltip);
  return (
    <Link
      to={option.link}
      className={`${classes.sidebarOption} ${
        activePage === option.title ? classes.activeSideOption : ""
      }`}
    >
      <CustomTooltip title={option.title} placement="right" arrow>
        <div
          className={classes.iconComponent}
          sx={{
            "& .css-10bqb7f-MuiSvgIcon-root": {
              color: activePage === option.title ? "#FFF !important" : "#000",
            },
          }}
        >
          {option.IconComponent}
        </div>
      </CustomTooltip>
    </Link>
  );
};

export default withStyles(styles)(SidebarOption);
