import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { ComposeNotification } from "./SendNotification";
import { AuthContext } from "contexts/AuthContext";
import { Box } from "@mui/material";
import Loading from "pages/analytics/loader/LoadingAnim";
import AnalyticsSidebar from "pages/analytics/sidebar_pages/AnalyticsSidebar";
import NotificationSidebar from "./Sidebar";
import NotifacationHistory from "./NotifacationHistory";
export const Notification = () => {
  const [report, setReport] = React.useState("1");
  const { setActivePage } = React.useContext(AuthContext);
  const handleOptionClick = (value) => {
    setReport(value);
  };
 
  useEffect(() => {

    setActivePage("Notification");
  }, []);
  
  return (
    <>
      <div style={{ display: "flex" }}>
      <NotificationSidebar report={report} handleOptionClick={handleOptionClick} />
      <Box sx={{ width: "calc(78%)" }}>
        {report === "1" ? (
          <ComposeNotification />
        )  : report === "2" ? (
          <NotifacationHistory />
        )  : (
          <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>
        )}
      </Box>
    </div>
    </>
  );
};
