import React, { useState } from "react";

// MUI
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// data
import { columns } from "..";

const TableHeaderComponent = () => {
  return (
    <TableRow sx={{ backgroundColor: "#F2F7FF" }}>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          align={column.align}
          sx={{
            minWidth: column.minWidth,
            backgroundColor: "#2a2d32",
            color: "#FFF !important",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeaderComponent;
