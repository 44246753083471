import React, { useContext, useEffect } from "react";

// Routers
import { BrowserRouter as Router } from "react-router-dom";
import AllRouters from "allRouters";

// MUI
import { withStyles } from "@mui/styles";
import styles from "./styles";

// Components
import { AuthContext } from "contexts/AuthContext";

// context
import Base from "components/base";

const App = () => {
  const { isLoggedIn, getUserDetails, user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      getUserDetails();
    }
  }, [isLoggedIn]);

  return (
    <>
      <Router>
        <Base>
          <AllRouters />
        </Base>
      </Router>
    </>
  );
};

export default withStyles(styles)(App);
