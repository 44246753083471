import React, { useState, createContext } from "react";

// custom hooks
import useLocalStorage from "customHooks/useLocalStorage";

// pages
import { getAdminData, getUserState } from "pages/auth/login/actions";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const { getLocalStorage, setLocalStorage } = useLocalStorage();
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!getLocalStorage("user_id") || false
  );
  const [userState, setUserState] = useState(null);
  const [activePage, setActivePage] = useState("");
  const [isProtected, setIsProtected] = useState(true);

  const formatName = (name) => {
    if (name === null || !name) {
      return "";
    }
    const words = name?.split(" ");
    for (let i = 0; i < words?.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
    }
    return words?.join(" ");
  };

  const updateUser = async (user) => {
    console.log(user);
    user.name = formatName(user.name);
    const response = await getUserState();
    let state = null;
    console.log(response);
    for (let i = 0; i < response?.data?.state.length; i++) {
      if (response?.data?.state[i].id === user.state_id) {
        state = response?.data?.state[i];
        break;
      }
    }
    console.log(state);
    user.state = state.state;
    setUser(user);
    setUserState(state.state);
  };

  const getUserDetails = async () => {
    const userId = await getLocalStorage("user_id");
    if (!userId) {
      setIsLoggedIn(false);
      return;
    }
    const res = await getAdminData(userId);
    if (res.error) {
      setIsLoggedIn(false);
    }
    let isSuperAdmin = res?.data?.role === "superAdmin" || false;
    setIsProtected(isSuperAdmin);
    await updateUser(res.data);
    setIsLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        user,
        setIsLoggedIn,
        updateUser,
        userState,
        activePage,
        setActivePage,
        setUser,
        isProtected,
        getUserDetails,
        setIsLoggedIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
