import React, { useState } from "react";

// packages
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";

// MUI
import { withStyles } from "@mui/styles";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";

// styles
const styles = {
  dateSection: {
    padding: 0,
    boxSizing: "border-box",
    border: "1px solid #D1E6FF",
    borderRadius: "4px",
    width: "220px",
    padding: "0 3px",
    height: "44px",
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  calenderDiv: {
    position: "absolute",
    top: "10px",
    right: 30,
    top: 47,
    backgroundColor: "#FFF",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;",
    zIndex: "100000000000000000 !important",
    "& .react-daterange-picker .react-daterange-picker__wrapper": {
      display: "none",
    },
    "& .react-calendar": {
      border: "none !important",
      padding: "10px",
      boxShadow: "0 0 5px gray",
      borderRadius: "5px",
    },
    "& .react-calendar__tile--rangeStart": {
      borderTopLeftRadius: "5px",
    },
    "& .react-calendar__tile--rangeEnd": {
      borderBottomRightRadius: "5px",
    },
  },
  dateRangeSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    position: "relative",
  },
  showDateSection: {
    padding: 0,
    margin: 0,
    height: "100%",
    display: "flex !important",
    fontFamily: "Public Sans !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
    letterSpacing: "0.02em",
    color: "#303030 !important",
    justifyContent: "center !important",
    flexWrap: "wrap !important",
    alignItems: "center !important",
  },
};

const DateRangeInput = (props) => {
  const [open, setOpen] = useState(false);
  const { classes } = props;
  return (
    <Box className={classes.dateRangeSection}>
      <Box className={classes.dateSection} onClick={() => setOpen(!open)}>
        <CalendarTodayOutlined
          onClick={() => setOpen(!open)}
          sx={{ marginRight: "10px" }}
        />
        <Typography className={classes.showDateSection}>
          {moment(props.date[0]).format("DD/MM/YYYY")} -{" "}
          {moment(props.date[1]).format("DD/MM/YYYY")}
        </Typography>
      </Box>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setOpen(false)}
      >
        <Box className={classes.calenderDiv}>
          {open ? (
            <DateRangePicker
              value={props.date}
              isOpen={open}
              format="y-MM-dd"
              calendarIcon={null}
              onChange={(value) => {
                props.handleDateChange(value);
                setOpen(false);
              }}
              style={{
                overFlow: "visible",
              }}
            />
          ) : null}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default withStyles(styles)(DateRangeInput);
