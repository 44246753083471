import React from "react";

// MUI
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";

// styles
import styles from "./styles";
import { withStyles } from "@mui/styles";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";

// assets
import NoDataImage from "assets/images/no-data-concept-illustration_114360-536.avif";

const TableComponent = ({
  classes,
  maxCount,
  limit,
  page,
  handleChangePage,
  columns,
  rows,
  TableBodyComponent,
  TableHeaderComponent,
  pagination,
  loading,
  ...otherProps
}) => {
  return (
    <Paper sx={{ width: "99%", overflow: "hidden", marginBottom: "20px" }}>
      <TableContainer
        className={classes.customScrollbar}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableHeaderComponent {...otherProps} />
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Box className={classes.loadingTableCell}>
                    <CircularProgress color="inherit" size={10} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : rows.length ? (
              <TableBodyComponent
                rows={rows}
                page={page}
                limit={limit}
                {...otherProps}
              />
            ) : (
              <TableRow
                sx={{
                  "&:hover": { backgroundColor: "transparent !important" },
                }}
              >
                <TableCell colSpan={columns.length}>
                  <Box className={classes.noDataSection}>
                    <img
                      src={NoDataImage}
                      className={classes.noDataSectionImage}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          limitoptions={[]}
          labelRowsPerPage=""
          component="div"
          count={maxCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          sx={{
            "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
              backgroundColor: "#f1f2f8 !important",
            },
            "& .MuiTablePagination-select": {
              display: "none !important",
            },
            "& .MuiTablePagination-selectIcon": {
              display: "none !important",
            },
          }}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(TableComponent);
