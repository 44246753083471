import React, { useState } from "react";

// MUI
import {
  CancelOutlined,
  CheckCircleOutline,
  ErrorOutlineRounded,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";

// components
import PostStatusButton from "./PostStatusButton";

// styles
import styles from "./styles";

const PostStatusType = (props) => {
  const { classes } = props;
  const statusOptions = [
    {
      title: "Pending",
      count: props.postCounts.Pending_count,
      value: "Pending",
      IconComponent: ErrorOutlineRounded,
    },
    {
      title: "Approved",
      count: props.postCounts.Approved_count,
      value: "Approved",
      IconComponent: CheckCircleOutline,
    },

    {
      title: "Rejected",
      count: props.postCounts.Rejected_count,
      value: "Rejected",
      IconComponent: CancelOutlined,
    },
  ];
  return (
    <Box sx={{ marginTop: "2px", paddingLeft: "20px", height: "44px" }}>
      {statusOptions.map((option, index) => (
        <PostStatusButton
          key={index}
          option={option}
          postType={props.postType}
          selectPostType={props.selectPostType}
        />
      ))}
    </Box>
  );
};

export default withStyles(styles)(PostStatusType);
