import React, { useContext } from "react";
// contexts
import { AuthContext } from "contexts/AuthContext";

// packages
import { Outlet } from "react-router-dom";

// pages
import UnAuthorized from "pages/unauthorized";

const ProtectedRoute = () => {
  const { isProtected } = useContext(AuthContext);

  return isProtected ? <Outlet /> : <UnAuthorized />;
};

export default ProtectedRoute;
