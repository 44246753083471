const styles = {
  cardSection: {
    border: "1px solid #E7F2FF",
    borderRadius: "14px",
    background: "#FFF",
    marginBottom: "20px",
    padding: "20px",
    paddingTop: "5px",
    maxHeight: "85vh",
    width: "98%",
  },
  taggedCategorySection: {
    maxHeight: "200px",
    padding: "10px 0 0 2px",
    transition: "all 0.5s ease-in-out",
    overflowY: "auto",
    maxHeight: "87%",
    "&::-webkit-scrollbar": {
      width: 10,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  tagListSection: {
    maxHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "10px 0 0 10px",
    // paddingLeft: "16px",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: 10,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  taggedOptionsSection: {
    minHeight: "230px",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 10,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  leftSection: {
    height: "250px",
    width: "23%",
  },
  video: {
    display: "flex",
    alignItems: "center",
    height: "280px !important",
    maxWidth: "280px !important",
  },
  leftTopSection: {
    width: "280px !important",
    height: "280px",
    cursor: "pointer",
    boxShadow: "0px 0px 16px rgba(17, 17, 26, 0.1)",
    borderRadius: "4px",
    marginBottom: "30px",
    backgroundColor: "#FFF"
  },
  tagsLeftSection: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "30px",
    width: "56%",
  },
  tagsSection: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "330px",
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    width: "76%",
  },
  cardImageContent: {
    height: "100%",
    objectFit: "cover",
  },
  taggedSection: {
    maxHeight: "300px",
    width: "55%",
    border: "1px solid #E7F2FF",
    borderRadius: 4,
  },
  tagCategoryTitle: {
    // marginBottom: "10px !important",
    fontFamily: "Public Sans !important",
    paddingLeft: "10px",
  },
  tagCategoryTitleColor: {
    color: "#2a2d32",
    borderRadius: "25px",
    fontWeight: "700 !important",
    backgroundColor: "#CAD5E2",
    transition: "all 0.3s ease-in-out",
    paddingRight: "10px",
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontWeight400: {
    fontWeight: 400,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorNotAllowed: {
    cursor: "not-allowed",
  },
  tagSectionTop: {
    margin: 0,
    padding: 0,
    boxSizing: "boder-box",
    background: "#2a2d32",
    width: "100%",
    height: 42,
    textAlign: "start",
    paddingLeft: 10,
    alignItems: "center",
    display: "flex",
    fontFamily: "Public Sans",
    fontSize: "16px",
    letterSpacing: "0.02em",
    lineHeight: "19px",
    color: "#FFF",
    fontWeight: 400,
    flexWrap: "wrap",
    borderRadius: "10px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  inputSection: {
    margin: "5px 10px 10px 10px",
    border: "1px solid #75B3FB",
    borderRadius: 30,
    paddingLeft: "5px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    flexDirection: "row",

    "& input": {
      width: "77%",
      border: "none",
      outline: "none",
      // borderRadius: 30,
      "&:focus": {
        outline: "none",
        border: "none",
      },
    },
  },
  bannerInput: {
    border: "1px solid #75B3FB !important",
    borderRadius: "4px",
    "&:hover": {
      border: "1px solid #75B3FB !important",
    },
    "&.Mui-focused .MuiInputBase-colorPrimary": {
      border: "1px solid #75B3FB !important",
    },
  },
  contentSection: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  postTitle: {
    fontFamily: "Public Sans !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.02em !important",
    color: "#303030 !important",
    marginBottom: "12px !important",
  },
  taggedOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "14px 8px 14px 12px",
    gap: "10px",
    minWidth: 73,
    height: 36,
    background: "#2a2d32",
    borderRadius: "30px",
    marginRight: "10px",
    marginBottom: "10px",
    color: "#FFF",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "15px",
    letterSpacing: "0.02em",
  },
  closeIcon: {
    fontSize: "13px !important",
  },
  graishColor: {
    color: "#C4C4C4",
  },
  rightBottomSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rightBottomInnerSection: {
    display: "flex",
    height: "30px",
    marginTop: "20px",
    width: "60%",
    gap: "20px",
    "@media (max-width: 1430px)": {
      width: "70%",
    },
  },
  justifyContentSpacebtwn: {
    justifyContent: "space-between",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  rejectBtn: {
    cursor: "pointer",
    color: "#FF5252",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    height: "43px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    borderRadius: "23px",
    padding: "0 20px",
    minWidth: "150px",
    transition: "border 0.3s ease-in",
    border: "1px solid #FF5252",
    color: "#FF5252",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "#FF5252",
      color: "#FFF",
    },
  },
  selectedTaggedOptionsSection: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "300px",
    overflowY: "auto",
    overflowX: "hidden",
    minHeight: "300px",
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "16px",
    alignContent: "flex-start",
    "&::-webkit-scrollbar": {
      width: 10,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  alignNoContentText: {
    justifyContent: "center",
    padding: 0,
    alignItems: "center",
    alignContent: "center",
  },
  inputSearch: {
    border: "none !important",
    "&:focus": {
      border: "none !important",
    },
  },
  detailSection: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "row",
  },
  titleName: {
    color: "#787575",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: "0.02em",
    minWidth: "78px",
  },
  valueData: {
    color: "#787575",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: "0.02em",
  },
  addNewTagButtonSection: {
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "0.02em",
    color: "#3B5998",
    margin: 0,
    height: 43,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  submitButtonSection: {
    fontStyle: "Public Sans",
    width: 150,
    height: 43,
    color: "#FFF !important",
    padding: "12px 35px !important",
    background: "rgb(42, 45, 50) !important",
    border: "none",
    borderRadius: "38px !important",
    cursor: "pointer",
  },
  taggedAddSection: {
    width: "39%",
    maxHeight: "343px",
    "@media (maxWidth: 1200px)": {
      width: "35%",
    },
  },
};

export default styles;
