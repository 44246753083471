const styles = {
  root: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    overflow: "auto",
    fontFamily: "Public Sans !important",
    fontStyle: "normal !important",
  },
  sidebar: {
    height: "100%",
    margin: 0,
    marginRight: "6%",
    padding: 0,
    boxSizing: "border-box",
  },
  main: {
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  authBody: {
    // marginTop: "51px",
    paddingTop: "5px",
    boxShadow: "0 -4px 4px rgba(0, 0, 0, 0.09)",
    width: "92%",
    // overflowY: "auto",
  },
  nonAuthBody: {
    boxShadow: "0 -4px 4px rgba(0, 0, 0, 0.09)",
    width: "100%",
  },

  scrollbar: {
    /* Width of the scrollbar */
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "30px",
    },
    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  } 
};

export default styles;
