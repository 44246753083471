import React, { useState } from "react";

// MUI
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";

// data
import { columns } from "..";

// styles
import styles from "../styles";
import RowConent from "./RowConent";

const TableBodyComponent = ({
  classes,
  rows,
  handleApprovedReject,
  postType,
  handleEdit,
}) => {
  return (
    <>
      {rows.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.rowId}
            sx={{
              transition: "all 0.4s ease-in-out",
              "&:hover": { backgroundColor: "#D1E6FF !important" },
            }}
          >
            {columns.map((column, index) => {
              return (
                <TableCell
                  key={index}
                  align={column.align}
                  sx={{ border: "none" }}
                >
                  <RowConent
                    row={row}
                    column={column}
                    handleApprovedReject={handleApprovedReject}
                    postType={postType}
                    handleEdit={handleEdit}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default withStyles(styles)(TableBodyComponent);
