import React, { useContext, useEffect, useState } from "react";

// MUI
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

// packages
import moment from "moment";

// assets
import NoDataImage from "assets/images/no-data-concept-illustration_114360-536.avif";

// components
import DateRangeInput from "components/input/DateRangeInput";
import PostImageVideoType from "components/postImageVideoType";
import PostStatusType from "components/postStatusType";
import PaginationComp from "components/pagination";
import PostCard from "components/card";
import PostCardSkeleton from "components/customSkeletons/PostCardSkeleton";

// Contexts
import { AuthContext } from "contexts/AuthContext";
import {
  editPost,
  getAllCounts,
  getAllCountsv2,
  getLangListAPI,
  getPageListAPI,
  getStateListAPI,
  getTagListsByCategory,
  getUploadedPosts,
  getUploadedPostsv2,
  rejectPost,
  verifyPost,
} from "./actions";
import { AlertContext } from "contexts/AlertContext";
import { withStyles } from "@mui/styles";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// styles
import styles from "./styles";
import Button from "components/input/Button";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const UploadedPosts = (props) => {
  const { classes } = props;

  const [contentType, setContentType] = useState("Video");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [postType, setPostType] = useState("Pending");
  const [limit] = useState(40);
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [tagsData, setTagsData] = useState({});
  const [date, setDate] = useState([moment().subtract(7, "d")._d, moment()._d]);
  const [adminLoading, setAdminLoading] = useState(true);
  const [alreadyTaggedOptions, setAlreadyTaggedOptions] = useState([]);
  const [postCounts, setPostsCounts] = useState({
    Approved_count: 0,
    Pending_count: 0,
    Rejected_count: 0,
  });
  const [showTopBar, setShowTopbar] = useState(true);
  const { userState, activePage, setActivePage } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  // console.log("alreadyTaggedOptions", alreadyTaggedOptions);
  const selectContentType = (value) => {
    setContentType(value);
  };

  const getTagCatagories = async () => {
    const { data } = await getTagListsByCategory();
    setTagsData(data.data);
  };

  const selectPostType = async (type) => {
    setPostType(type);
    setCurrentPage(1);
    setOffset(0);
    await getPosts(date, 0, type);
  };

  const handlePageChange = async (page) => {
    const prevPage = page === 1 ? 1 : page - 1;
    if (page === 1) {
      setOffset(0);
      getPosts(date, 0, postType);
    } else {
      const currentOffset = prevPage * limit;
      setOffset(currentOffset);
      getPosts(date, currentOffset, postType);
    }
    setCurrentPage(page);
  };

  const selectActivePage = (pageName) => {
    setActivePage(pageName);
  };

  const handleDateChange = (value) => {
    setDate(value);
    getPosts(value, 0, postType);
    
  };

  const formatDate = (date) => {
    let startDate = moment(date[0]).format("YYYY-MM-DD");
    let endDate = moment(date[1]).format("YYYY-MM-DD");
    startDate = startDate + " 00:00:00";
    endDate = endDate + " 23:59:59";
    return { startDate, endDate };
  };
  const getCounts = async (date = new Date()) => {
    const formattedDate = formatDate(date);
    const response = await getAllCounts(
      formattedDate.startDate,
      formattedDate.endDate,
      userState
    );
    setPostsCounts(response.data);
  };

  const getCountsv2 = async ( 
    postType,
    startDate,
    endDate,
    state_ids,
    lang_ids,
    page_ids) => {
    const formattedDate = formatDate(date);
    const response = await getAllCountsv2(
      postType,
    startDate,
    endDate,
    state_ids,
    lang_ids,
    page_ids
    );
    let count = {
      Approved_count: response.data.Approved_count,
      Pending_count: response.data.Pending_count,
      Rejected_count:response.data.Rejected_count,
    }
    setPostsCounts(count);
  };

  const getFilteredPosts = (data, contentType = "Image") => {
    const filteredPosts = data?.filter(
      (post) => post.post_type === contentType
    );
    return filteredPosts;
  };

  const changeContentType = (type) => {
    setContentType(type);
    setFilteredPosts(() => getFilteredPosts(posts, type));
  };

  const getPosts = async (
    date = [moment().subtract(7, "d")._d, moment()._d],
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      setPosts([]);
      setAdminLoading(true);
      const state_ids=[]
      const lang_ids=[]
      const page_ids=[]

      selectedStates.map((e)=>{
        state_ids.push(e.id)
      });

      selectedLang.map((e)=>{
        lang_ids.push(e.id)
      });

      selectedPages.map((e)=>{
        page_ids.push(e.page_id)
      });

      console.log(state_ids, page_ids, lang_ids)
      
      const formattedDate = formatDate(date);
      const { data, error } = await getUploadedPostsv2(
        offset,
        postType,
        limit,
        formattedDate.startDate,
        formattedDate.endDate,
        state_ids,
        lang_ids,
        page_ids
        
      );
      console.log(data);
      getCountsv2( 
        postType,
    
        formattedDate.startDate,
        formattedDate.endDate,
        state_ids,
        lang_ids,
        page_ids);
      if (data === null) {
        setPageCount(1);
        setCurrentPage(1);
        setPosts([]);
        setFilteredPosts([]);
      } else {
        setCurrentPage(Math.ceil(offset / limit) + 1);
        setPageCount(Math.ceil(data["total posts"] / limit));
        const tagsList = { ...data["tag_list"] };
        for (let tag in data["pending_tags"]) {
          if (tagsList.hasOwnProperty(tag)) {
            tagsList[tag].push(...data["pending_tags"][tag]);
          } else {
            tagsList = { ...tagsList, ...tag };
          }
        }
        setAlreadyTaggedOptions(tagsList);
        setPosts(data.data);
        setFilteredPosts(() => getFilteredPosts(data.data, contentType));
      }
      setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const handlePostApproved = async (
    post_id,
    post_type,
    state_tag_id,
    lang_tag_id,
    verified_tags,
    pending_tags,
    vp9,
    highlighted
  ) => {
    const response = await verifyPost(
      post_id,
      post_type,
      state_tag_id,
      lang_tag_id,
      verified_tags,
      pending_tags,
      vp9,
      highlighted
    );
    if (response.error) {
      throw new Error(response.error);
    }
    setPosts((oldPosts) =>
      oldPosts?.filter((post) => post.post_id !== post_id)
    );
  };


  const handleEditPost = async (
    url,
    caption,
    post_type,
    post_id,
    thumbnail_url
  ) => {
    const response = await editPost(
      url,
      caption,
      post_type,
      post_id,
      thumbnail_url
    );
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  };

  const handleRejectPost = async (post_id) => {
    try {
      const response = await rejectPost(post_id);
      if (response.error) {
        showAlert("Something went wrong", "error");
        return;
      }
      showAlert("Post rejected successfully", "success");
      await getPosts(date, offset, postType);
    } catch (error) {
      showAlert("Something went wrong! Please try again.", "error");
    }
  };

  const [stateListN, setStateNList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [pageList, setPageList] = useState([]);

  const getStateList = async () => {
    try {
      const { data, error } = await getStateListAPI();

      console.log(data);
      if (data === null) {
        setStateNList([]);
      } else {
        setStateNList(data.state);
     
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };
  let defaultLang = [ {
    "id": 455,
    "language": "Hindi",
    "display_name": "हिंदी (Hindi)",
    "image_link": null,
    "created_at": "2023-02-17T05:58:09",
    "updated_at": "2023-07-07T05:58:09",
    "is_active": true
}]
  const getLangList = async () => {
    try {
      const { data, error } = await getLangListAPI();

      console.log(data);
      if (data === null) {
        setLangList([]);
      } else {
        setLangList(data.lang);
        // defaultLang = data.lang.filter((e)=>{return e.id === 455})
        // setSelectedLang(defaultLang)
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const getPageList = async () => {
    try {
      const { data, error } = await getPageListAPI();

      console.log(data);
      if (data === null) {
        setPageList([]);
      } else {
        setPageList(data.page_list);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedLang, setSelectedLang] = useState(defaultLang);
  const [selectedPages, setSelectedPages] = useState([]);

  useEffect(() => {
    if (date.length && userState) {
      getStateList();
      getLangList();
      getPageList();
      getTagCatagories();
      getPosts();
    }
    selectActivePage("Content Tagging");
  }, [userState]);

  return (
    <Box className={classes.uploadedPostSection}>
      <Box
        className={classes.uploadedPostsTopSection}
        sx={{
          display: !showTopBar && "none",
          transition: "all 0.6s ease-in-out",
        }}
      >
        <Box className={classes.uploadedNavigationTop}>
          {/* <Box>
            <PostStatusType
              postCounts={postCounts}
              postType={postType}
              selectPostType={selectPostType}
            />
          </Box> */}

        <Box></Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <PaginationComp
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>

        <Box className={classes.postTypeBtnSection} sx={{ display: "flex", gap: 2, alignItems: "center", 
        justifyContent:'start', flexWrap: "wrap", paddingTop:1, paddingBottom:0.1 }}>
   
    <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={"Pending"}
                size="small"
                onChange={(e) => {
                  selectPostType(e.target.value);
                }}
              >
                <MenuItem value={"Pending"}>{"Pending (" +postCounts.Pending_count+ ")"}</MenuItem>
                <MenuItem value={"Approved"}>{"Approved (" + postCounts.Approved_count+ ")"}</MenuItem>
                <MenuItem value={"Rejected"}>{"Rejected ("  + postCounts.Rejected_count+ ")"}</MenuItem>

              </Select>
            </FormControl>
         
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={"Video"}
                size="small"
                onChange={(e) => {
                  selectContentType(e.target.value);
                  changeContentType(e.target.value);
                }}
              >
                <MenuItem value={"Image"}>Images</MenuItem>
                <MenuItem value={"Video"}>Videos</MenuItem>
              </Select>
            </FormControl>
  
      
            {/* <PostImageVideoType
              contentType={contentType}
              changeContentType={changeContentType}
              handleClick={selectContentType}
              options={[
                { title: "Images", value: "Image" },
                { title: "Videos", value: "Video" },
              ]}
            /> */}
        
          
            <FormControl>
              <Autocomplete
              
              disableCloseOnSelect
                multiple
                limitTags={0}
                id="multiple-state-tags"
                size="small"
                options={stateListN}
                getOptionLabel={(option) => option.state}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSelectedStates(newValue);
                }}
                defaultValue={[]}
             
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.state}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline={false}
                    label="State"
                    placeholder=""
                  />
                )}
                sx={{ width: 200 }}
              />
            </FormControl>
            <FormControl>
            <Autocomplete
            disableCloseOnSelect
                multiple
                limitTags={0}
                id="multiple-language-tags"
                size="small"
                options={langList}
                getOptionLabel={(option) => option.language}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSelectedLang(newValue);
                }}
                defaultValue={defaultLang}
               isOptionEqualToValue={(option, value)=>{ return option.id === value.id}}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.language}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline={false}
                    label="Language"
                    placeholder=""
                  />
                )}
                sx={{ width: 200 }}
              />
            </FormControl>
            <FormControl>
            <Autocomplete
            disableCloseOnSelect
                multiple
                limitTags={0}
                id="multiple-page-tags"
                size="small"
                options={pageList}
                getOptionLabel={(option) => option.page_name}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSelectedPages(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.page_name}
                  </li>
                )}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline={false}
                    label="Page"
                    placeholder=""
                  />
                )}
                sx={{ width: 200 }}
              />
            </FormControl>
          <Box className={classes.dateSection}>
              <DateRangeInput date={date} handleDateChange={handleDateChange} />
            </Box>
           <div onClick={()=>{
            getPosts(date)
           }}>
           <Button
              
              variant="contained"
              color="primary"
              type="submit"
              sx={{  width:140 }}
            
            >
              Apply Filters
            </Button>
           </div>
         
         
        </Box>
      </Box>
      <Box
        className={classes.postsSections}
        sx={{
          marginTop: !showTopBar ? "25px" : "",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {adminLoading ? (
          <PostCardSkeleton />
        ) : filteredPosts.length ? (
          filteredPosts.map((post, index) => (
            <PostCard
              key={post.post_id}
              post={post}
              postNumber={index + 1}
              handleRejectPost={handleRejectPost}
              tagsData={tagsData}
              formatDate={formatDate}
              alreadyTaggedOptions={alreadyTaggedOptions}
              postType={postType}
              handleEditPost={handleEditPost}
              handlePostApproved={handlePostApproved}
              date={date}
              userState={userState}
              setPostsCounts={setPostsCounts}
              setFilteredPosts={setFilteredPosts}
              setPosts={setPosts}
              offset={offset}
              getPosts={getPosts}
              getTagCatagories={getTagCatagories}
            />
          ))
        ) : (
          <Box className={classes.noDataSection}>
            <img src={NoDataImage} className={classes.noDataSectionImage} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(UploadedPosts);
