import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { withStyles } from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#4C4FF8" },
    secondary: { main: "#121212", light: "#474458" },
  },
  typography: {
    fontFamily: ["Public Sans", "muli", "sans-serif"].join(","),
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "none",
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
  },
});

const cssVariables = (theme) => ({
  "@global": {
    ":root": {
      "--color-primary": theme.palette.primary.main,
      "--color-secondary": theme.palette.secondary.main,
      "--color-input": "#70707066",
      "--color-input-dark": "#9E9E9E",
    },
    "*,*::before,*::after": {
      margin: 0,
      padding: 0,
      "box-sizing": "border-box",
    },
    ".MuiButton-root": {
      textTransform: "none!important",
    },
    img: {
      width: "100%",
      height: "100%",
    },
    li: {
      listStyleType: "none",
    },
    a: {
      textDecoration: "none",
    },
    ".MuiTypography-body1": {
      fontSize: "1em",
      fontWeight: "inherit",
    },
    ".MuiInputBase-input.Mui-disabled": {
      cursor: "not-allowed",
    },
    ".MuiCircularProgress-root": {
      width: "28px!important",
      height: "28px!important",
    },
    
  },
});

const CssRoot = withStyles(cssVariables)(() => {
  return <React.Fragment></React.Fragment>;
});

const CSSThemeProvider = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssRoot />
      {props.children}
    </ThemeProvider>
  );
};

export default CSSThemeProvider;
