import { Edit } from '@mui/icons-material';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import './EditStyle.css'

import { AlertContext } from "contexts/AlertContext";
import { editBucket, editCaptionFeatured } from "./actions";

const SelectStateBucket = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [stateId, setStateID] = useState(-1);
    const [stateName, setStateName] = useState(props.value);
    const [stateList, setStateList] = useState(props.stateList
    );
    
  const [displayStateId, setDisplayStateID] = useState([]);

    const { showAlert } = useContext(AlertContext);
    const handleStateUpdate = async (state_id) => {
      if(displayStateId.length === 0){
        setEditMode(false);
        return;
      }
      const { data, error } = await editBucket(props.post_id, props.is_active, undefined, state_id);
      if(data){
        let dispTemp = [];
        showAlert("Updated Sucessfully!", "success");
        for( let i = 0; i< stateList.length; i++){
          if( state_id.includes(stateList[i].id)){
            dispTemp.push(stateList[i].state)
          }
      }
      setStateName(dispTemp.join(", "))
      
      }
      if(error){
        showAlert("Something Went Wrong!", "error");
      }
      setEditMode(false);
    };

    useEffect(() => {}, [editMode]);
    return (
      <>
        {!editMode ? (
           <div
           className="language-display"
           onClick={() => {
               setEditMode(true);
               
           }}
       >
           {stateName}
           <Edit className="edit-icon" />
       </div>
   ) : (
    <>
    <FormControl
  >
    <InputLabel>Select States</InputLabel>
    <Select
    sx={{minWidth:400}}
                  multiple
                  value={displayStateId}
                  onChange={(e) => {
                    setDisplayStateID(e.target.value);
                    
                  }}
                  label="Select States"
                >
                   {stateList.map((state, index) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.state}
                    </MenuItem>
                  ))} 
                </Select>
  </FormControl>
  <div></div>
   <Button variant="contained"
   color="primary" sx={{marginTop:"8px"}} onClick={()=>{
    handleStateUpdate(displayStateId); 
 }}>OK</Button> 
    </>
        )}
      </>
    );
  };

export default SelectStateBucket
