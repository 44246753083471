import React from "react";
import lottie from "lottie-web";
import loadingAnimation from "./loading.json";


const Loading = () => {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#react-logo"),
          autoplay: true,
          animationData: loadingAnimation
        });
      }, []);
  return (
    <div className="flex items-center justify-center h-full">
      <div style={{ width: 200, height: 200 }} id="react-logo" />
    </div>
  )
}

export default Loading

