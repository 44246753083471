import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { getContentCategory, getContentSupply, getOverallReport } from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";

const columns = [
  {
    id: "date",
    label: "Date",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "signup_count",
    label: "New Signups",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "new_content_added",
    label: "New Content Added",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total_available_content",
    label: "Total Available Content",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "unique_count",
    label: "Unique Content Consumed",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  
  {
    id: "generated_videos",
    label: "Generated Videos",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "overall_download",
    label: "Downloads\nOverall | Generated",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "overall_share",
    label: "Share\nOverall | Generated",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "overall_like",
    label: "Like\nOverall | Generated",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },


 
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
    date,
    signup_count= 0,
            new_content_added= 0,
            total_available_content= 0,
            unique_count= 0,
           
            generated_videos= 0,
            overall_download= 0,
            
            overall_share= 0,
           
            overall_like= 0,
            
  
) {
  return {
    date,
    signup_count,
    new_content_added,
            total_available_content,
            unique_count,
           
            generated_videos,
            overall_download,
           
            overall_share,
        
            overall_like,
           

  };
}

//let rows = [];

export default function OverallReport() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [date, setDate] = useState([moment().subtract(2, "d")._d, moment().subtract(1, "d")._d,]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getContentCategoryData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getContentCategoryData = async (
    date,
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getOverallReport(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
        let rowData = [];
        data?.data?.forEach((tablerow, index) => {
          console.log(tablerow);
          
          rowData.push(
            createData(
              formatReadableDate(tablerow.date),
              tablerow.signup_count,
              tablerow.new_content_added,
              tablerow.total_available_content,
              tablerow.unique_count,
              

              tablerow.generated_videos,

              tablerow.overall_download+ " | " +
              tablerow.generated_download,
              tablerow.overall_share+ " | " +

              tablerow.generated_share,
              tablerow.overall_like+ " | " +
              tablerow.generated_like,

            )
          );
        });
        setRows(rowData);
        setRowsPerPage(rowData.length)
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getContentCategoryData([moment().subtract(2, "d")._d, moment().subtract(1, "d")._d,]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return rows.length ===0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
  
    
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Overall Report</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root" : {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
            {/* <TableRow sx={{ }}>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'}}
                     />
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={4}>
                Overall
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={4}>
                Generated
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'  }}
                    colSpan={4}>
                Video
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'  }}
                    colSpan={4}>
                Image
              </TableCell>
              <TableCell align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={4}>
                Post
              </TableCell>
           
            </TableRow> */}
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ backgroundColor: "rgba(209, 230, 255, 1)" , borderRight: index===20?'none':'1px solid rgba(0, 0, 0, 0.15)'}}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ borderRight: index===20?'none':'1px solid rgba(0, 0, 0, 0.15)'}}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
