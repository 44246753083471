import * as React from "react";
import ContentSupply from "./sidebar_pages/ContentSupply";
import { Box } from "@mui/material";
import AnalyticsSidebar from "./sidebar_pages/AnalyticsSidebar";
import { AuthContext } from "contexts/AuthContext";
import { useEffect } from "react";
import LifetimeEngagement from "./sidebar_pages/LifetimeEngagement";
import AcquisitionsReport from "./sidebar_pages/AcquisitionsReport";
import ContentCategoryEngagement from "./sidebar_pages/ContentCategoryEngagement";
import StateWiseWeekly from "./sidebar_pages/StateWiseWeekly";
import StateWiseDaily from "./sidebar_pages/StateWiseDaily";
import UserwiseReport from "./sidebar_pages/UserwiseReport";
import ContentSupplyDetailed from "./sidebar_pages/ContentSupplyDetailed";
import UserwiseReportGrassRoot from "./sidebar_pages/UserwiseReportGrassRoot";
import OverallReport from "./sidebar_pages/OverallReport";
import ContentSupplyStatus from "./sidebar_pages/ContentSupplyStatus";
import ContentSupplyStatusLang from "./sidebar_pages/ContentSupplyStatusLang";
import './sidebar_pages/StatewiseDaily.css'
import StatewiseUser from "./sidebar_pages/StatewiseUser";
import { useState } from "react";
import { getReportListAPI } from "./actions";
import Loading from "./loader/LoadingAnim";
import TaggingStatus from "./sidebar_pages/TaggingStatus";

export default function Analytics() {
  const [report, setReport] = React.useState();
  const { setActivePage } = React.useContext(AuthContext);
  const handleOptionClick = (value) => {
    setReport(value);
  };
  const [reportList, setReportList] = useState([]);
  const getReportList = async () => {
    try {
      const { data, error } = await getReportListAPI();

      console.log(data);
      if (data === null) {
        setReportList([]);
      } else {
        setReportList(data.reports);
   setReport(data?.reports[0].id.toString())
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {

    setActivePage("Analytics");
    getReportList()
  }, []);
 


  return (
    <div style={{ display: "flex" }}>
      <AnalyticsSidebar report={report} reportList={reportList} handleOptionClick={handleOptionClick} />
      <Box sx={{ width: "calc(78%)" }}>
        {report === "1" ? (
          <ContentSupply />
        )  : report === "2" ? (
          <ContentSupplyDetailed />
        ) : report === "3" ? (
          <LifetimeEngagement />
        ) : report === "4" ? (
          <AcquisitionsReport />
        ) : report === "5" ? (
          <ContentCategoryEngagement />
        ) : report === "6" ? (
          <StateWiseWeekly />
        ) : report === "7" ? (
          <StateWiseDaily />
        ): report === "8" ? (
          <StatewiseUser />
        )  : report === "9" ? (
          <UserwiseReport />
        ) : report === "10" ? (
          <UserwiseReportGrassRoot />
        ) : report === "11" ? (
          <OverallReport />
        ) : report === "12" ? (
          <ContentSupplyStatus />
        ) : report === "13" ? (
          <ContentSupplyStatusLang />
        ): report === "14" ? (
          <TaggingStatus />
        )  : (
          <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>
        )}
      </Box>
    </div>
  );
}
