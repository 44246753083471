import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

const AnalyticsSidebar = (props) => {
  const { report, handleOptionClick } = props;
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div style={{ position: "relative" }}>
      {/* <div
        onClick={() => {
          setShowSidebar((prev) => !prev);
        }}
        style={{
          height: "30px",
          width: "30px",
          backgroundColor: "#787898",
          position: "absolute",
          zIndex: "20",
          top: "8%",
          right: "20px",
        }}
      ></div> */}

      {showSidebar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "98.5vh",
            overflowY:'auto',
            minWidth: "25%",
            marginRight: "2rem",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid rgba(209, 230, 255, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              color: "rgba(120, 117, 117, 1)",
              fontWeight: "bold",
              paddingLeft: "8px",
              marginBottom: "20px",
              borderRadius: "40px",
              border: "2px solid rgba(209, 230, 255, 1)",
            }}
          >
            <SearchIcon />{" "}
            <input
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                paddingLeft: "8px",
                borderRadius: "50px",
                outline: "none",
              }}
              placeholder="Search Report"
            />
          </Box>


          {props.reportList.map((element)=>{

              return (  <div
                id={element.id.toString()}
                onClick={() => {
                  handleOptionClick(element.id.toString());
                }}
                style={{
                  color:
                    report === element.id.toString() ? "rgba(248, 65, 67, 1)" : "rgba(79, 79, 79, 1)",
                  fontWeight: report === element.id.toString() ? "bold" : "normal",
                  paddingBottom: "12px",
                  marginBottom: "15px",
                  cursor: "pointer",
                  borderBottom:
                    report === element.id.toString()
                      ? "1px solid rgba(248, 65, 67, 1)"
                      : "1px solid rgba(229, 229, 229, 1)",
                }}
              >
               {element.report}
              </div>)


          })
          
          
          
          }

    
        </Box>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AnalyticsSidebar;
