const styles = {
  uploadedPostSection: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  uploadedPostsTopSection: {
    position: "fixed",
    width: "88%",
    zIndex: "10",
    background: "#FFFFFF",
    paddingTop: "8px",
    paddingBottom: "10px",
    right: "4%",
    top: 0,
    transition: "all 0.6s ease-in-out",
    // "@media (max-width: 1199px)": {
    //   width: "93%",
    // },
  },
  uploadedNavigationTop: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    
  },
  dateSection: {
    display: "flex",
    justifyContent: "center",
  },
  postTypeBtnSection: {
    marginTop: "8px",
    //paddingLeft: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
   

    // paddingRight: "20px",
    
  },

  postsSections: {
    padding: "10px 15px 10px 3%",
    margin: "auto",
    marginTop: "100px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    position: "absolute",
    left: 0,
  },

  "@media screen and (max-width: 1490px)": {
    postsSections: {
      padding: "10px 15px 10px 3%",
      margin: "auto",
      marginTop: "150px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "center",
      position: "absolute",
      left: 0,
    }
 },
  noDataSection: {
    padding: "30px 15px 10px 15px",
    paddingTop: "10%",
    paddingLeft: "35%",
    color: "#9E9E9E",
  },
  noDataSectionImage: {
    width: "450px",
    height: "500px",
    margin: "auto",
    left: "28%",
    position: "absolute",
    top: "60%",
  },
  upDownArrow: {
    position: "fixed",
    right: "18px",
    top: "164px",
    cursor: "pointer",
    color: "#2a2d32",
    height: "36px !important",
    width: "36px !important",
  },
  upArrow: {
    transition: "all 0.4s ease-in-out",
    top: "55px",
  },
  downArrow: {
    transition: "transform 0.5s ease-in-out",
    transform: "rotate(180deg)",
  },
};

export default styles;
