import React from "react";

// MUI
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";

// styles
const styles = {
  generelButton: {
    borderRadius: "8px",
    padding: "0.7em 1em",
    fontSize: "0.875rem",
    minWidth: "64px",
    lineHeight: "1.75",
    fontWeight: 500,
    color: "#FFF",
  },
};

const ButtonInput = ({
  classes,
  children,
  variant = "outlined",
  classNames = "",
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="outlined"
      className={`${classes.generelButton} ${classNames}`}
      color="primary"
      disabled={disabled}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default withStyles(styles)(ButtonInput);
