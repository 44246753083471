import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import JsPDF from 'jspdf';
import Loading from "pages/analytics/loader/LoadingAnim";
import { getNotificationHistory } from "./actions";

const columns = [
  {
    id: "campaign",
    label: "Campaign",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "image",
    label: "Image",
    minWidth: 100,
    format: (value) =>value.toLocaleString("en-US"),
  },
  {
    id: "start_date",
    label: "Sent Date Time",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "state",
    label: "State",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "sends",
    label: "Sends",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "clicks",
    label: "Clicks",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "admin_name",
    label: "Admin Name",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "additional_fields",
    label: "Additional Fields",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour:"numeric",
    minute:"numeric",
    second:"numeric"
  });
};

function createData(
  campaign,
  image,
  start_date,
  status,
  state,
  sends,
  clicks,
  admin_name,
  additional_fields
) {
  return {
    campaign,
    image,
    start_date,
    status,
    state,
    sends,
    clicks,
    admin_name,
    additional_fields
  };
}

//let rows = [];

export default function NotifacationHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const generatePDF = () => {

    const report = new JsPDF('l','mm','a1');
    report.html(document.querySelector('#content_supply')).then(() => {
        report.save('content_supply.pdf');
    });}

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 

  const getHistory = async (
  
  ) => {
    try {
      const { data, error } = await getNotificationHistory(
        100,
        0
      );
     
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
      let  rowData = [];
        data?.notifications?.forEach((tablerow, index) => {
          console.log(tablerow);
          rowData.push(
            createData(
                tablerow.body,
                tablerow.image,
              formatReadableDate(tablerow.created_at),
              tablerow.status,
              tablerow.statename??"",
              tablerow.sends,
              tablerow.clicks,
              tablerow.admin_name,
              tablerow.additional_fields,
            )
          );
        });
        setRows(rowData);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => { 
      getHistory();
    
  }, []);

  return   rows.length === 0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
    <Box sx={{ paddingRight: "2rem", marginTop:"40px" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Notification History</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          
        </Box>
      </Box>
      
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
    
      
        <TableContainer sx={{ maxHeight: 440 }}>
        <div id="content_supply">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ backgroundColor: "rgba(209, 230, 255, 1)" , borderRight: index===9?'none':'1px solid rgba(0, 0, 0, 0.15)' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ borderRight: index===8?'none':'1px solid rgba(0, 0, 0, 0.15)'}}>
                      
                            { column.id === "image"? <img src={value}/>:
                            column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          </div>
        </TableContainer>
      
      </Paper>
    </Box>
  );
}
