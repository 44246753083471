import React, { useState, useContext } from "react";

// MUI
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";

// actions
import { addNewTag } from "pages/uploadedPosts/actions";

// contexts
import { AlertContext } from "contexts/AlertContext";

// styles
import styles from "./styles";

const AddNewTagCard = (props) => {
  const { classes } = props;

  const { showAlert } = useContext(AlertContext);

  const [selectedCategory, setSelectedCategory] = useState("Campaigns");
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const tagCategories = [
    "Campaigns",
    "Language",
    "Opponent Party",
    "Opponent Person",
    "Political Party (Self)",
    "Politician Name",
    "State",
    "Type of Posts",
  ];

  const handleSubmit = async () => {
    if (!newCategory.trim()) {
      showAlert("Please type a new tag", "error");
      return;
    }
    setLoading(true);
    try {
      await addNewTag(selectedCategory, newCategory, "Pending");
      await props.getTagCatagories();
      props.setOpenAddNewTagCard(false);
      showAlert("Tag added successfully.", "success");
      setLoading(false);
    } catch (error) {
      if (error.message.includes("400")) {
        showAlert("This tag is already present.", "error");
      } else {
        showAlert("Something went wrong! Please try again.", "error");
      }
      setLoading(false);
    }
  };

  return (
    <Dialog
      onClose={() => props.setOpenAddNewTagCard(false)}
      aria-labelledby="customized-dialog-title"
      open={props.openAddNewTagCard}
      className={classes.dialogSection}
    >
      <Box className={classes.dialogMainSection}>
        <Typography className={classes.title}>Add New Tag</Typography>
        <Box className={classes.mainSection}>
          <Box className={classes.leftPortion}>
            <Box className={classes.headerSection}>Tag Categories</Box>
            <Box className={classes.optionsSection}>
              {tagCategories.map((category, index) => (
                <Box
                  key={index}
                  onClick={() => setSelectedCategory(category)}
                  className={`${classes.category} ${
                    selectedCategory === category
                      ? classes.selectedCategory
                      : ""
                  }`}
                >
                  {category}
                </Box>
              ))}
            </Box>
          </Box>
          <Box className={classes.rightPortion}>
            <Box className={classes.rightTopPortion} sx={{ height: "80%" }}>
              <Box className={classes.headerSection}>Tag List</Box>
              <TextField
                className={classes.addNewTagTextField}
                onChange={(event) => setNewCategory(event.target.value)}
                placeholder="Enter Tag"
              />
            </Box>
            <Box className={classes.rightBottomPortion}>
              <Box
                sx={{ cursor: "pointer", color: "#FF5252" }}
                onClick={() => props.setOpenAddNewTagCard(false)}
              >
                Cancel
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "30px",
                    background: "#2a2d32 !important",
                    color: "#FFF",
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} />
                  ) : (
                    "Add Tag"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(AddNewTagCard);
