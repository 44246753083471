// packages
import AXIOS from "services/axios";

// utils
import { getConfig } from "utils/config";

export const getUploadedPosts = async (
  offset = 0,
  post_type = "Approved",
  limit = 20,
  start_date,
  end_date,
  state
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  const lang = await localStorage.getItem("lang_id");
  try {
    const { data } = await AXIOS.get(
      `v1/post/list/?start_date=${start_date}&end_date=${end_date}&limit=${limit}&status=${post_type}&offset=${offset}&state=${state}&lang=${lang}`,
      config
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getUploadedPostsv2 = async (
  offset = 0,
  post_type = "Approved",
  limit = 20,
  start_date,
  end_date,
  state_ids=[],
  lang_ids=[],
  page_ids=[]
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.post(
      `v2/post/list/`,{
        "start_date": start_date,
        "end_date": end_date,
        "state_ids":state_ids,
        "page_ids":page_ids,
        "language_ids":lang_ids,
        "status":post_type,
        "limit": limit,
        "offset":offset

      }, config
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getStateListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/states/admin_states/`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getLangListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/languages/list`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};


export const getPageListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/page/get_all_pages`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getTagListsByCategory = async () => {
  const response = { data: null, error: null };
  try {
    const config = await getConfig();
    const { data } = await AXIOS.get(`v1/tags/list_by_category/`, config);
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const verifyPost = async (
  post_id,
  post_type,
  state_tag_id,
  lang_tag_id,
  verified_tags,
  pending_tags = [],
  vp9,
  highlighted,
) => {
  const response = { data: null, error: null };
  try {
    const config = await getConfig();
    const { data, error } = await AXIOS.put(
      `${process.env.REACT_APP_API_SHORT_PATH}v1/post/verify/`,
      {
        post_id,
        post_type: post_type,
        state_tag_id,
        lang_tag_id,
        verified_tags,
        pending_tags,
        vp9,
        highlighted,

      },
      config
    );
    if (error) {
      throw new Error(error);
    } else {
      response.data = data;
    }
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const rejectPost = async (post_id) => {
  const response = { data: null, error: null };
  try {
    const config = await getConfig();
    const { data } = await AXIOS.put(`v1/post/${post_id}/reject`, {}, config);
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getAllCounts = async (start_date, end_date, state) => {
  
  const response = { data: null, error: null };
  const config = await getConfig();
  const lang =await localStorage.getItem("lang_id");
  try {
    const { data } = await AXIOS.get(
      `v1/post/list_total_post_count?start_date=${start_date}&end_date=${end_date}&state=${state}&lang=${lang}`, 
      config
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getAllCountsv2 = async ( 
  post_type = "Approved",
  start_date,
  end_date,
  state_ids=[],
  lang_ids=[],
  page_ids=[]) => {
  
  const response = { data: null, error: null };
  const config = await getConfig();
  const lang =await localStorage.getItem("lang_id");
  try {
    const { data } = await AXIOS.post(
      `v1/posts/list_total_post_count_by_filters`,{
        "start_date": start_date,
        "end_date": end_date,
        "state_ids":state_ids,
        "page_ids":page_ids,
        "language_ids":lang_ids,
        "status":post_type

      }, 
      config
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const editPost = async (
  url,
  caption,
  post_type,
  post_id,
  thumbnail_url = null
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    config.headers = {
      ...config.headers,
      "content-type": "multipart/form-data",
    };
    const body = {
      url: url,
      caption: caption,
      post_type: post_type,
      post_id: post_id,
    };
    if (thumbnail_url !== null) {
      body.thumbnail_url = thumbnail_url;
    }
    const {data, error} = await AXIOS.put(
      `${process.env.REACT_APP_API_SHORT_PATH}v1/post/edit`,
      body,
      config
    );
    if (error) {
      throw new Error(error);
    } else {
      response.data = data;
    }
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const addNewTag = async (
  category_name,
  tag_name,
  tag_status = "Pending"
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.post(
      "v1/tags/add/",
      { category_name, tag_name, tag_status },
      config
    );
    if (error) {
      throw new Error(error);
    }
    response.data = data.data;
  } catch (error) {
    response.error = error.response.data.error;
    throw new Error(error);
  }

  return response;
};
