import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  addBucket,
  getBucketListAPI,
  getLangListAPI,
  getPageListAPI,
  getStateListAPI,
  uploadDataAPI,
} from "./actions";
import { AlertContext } from "contexts/AlertContext";
import ReactVirtualizedTable from "./UploadHistory";
import BucketTable from "./BucketTable";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddEditBuckets = () => {
  const { setActivePage } = React.useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  const [stateList, setStateList] = useState([]);
  const [captionText, setCaptionText] = useState("");
  const [displayStateId, setDisplayStateID] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStateList = async () => {
    try {
      const { data, error } = await getStateListAPI();

      console.log(data);
      if (data === null) {
        setStateList([]);
      } else {
        setStateList(data.state);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };


  const uploadVideo = async (
    caption,
    stateIds,
   
  ) => {
    setLoading(true);
    try {
      const { data, error } = await addBucket(
        caption,
        stateIds
      );

      if (error) {
        showAlert(error, "error");
      } else {
        setCaptionText("");
        setDisplayStateID([]);
        showAlert("Uploaded Successfully!", "success");
      }
      setLoading(false);
    } catch (error) {
      showAlert(error, "error");
    }
  };


  useEffect(() => {
    getStateList();
   
  }, []);
  const classes = useStyles();

  return (
    <Box
      sx={{
        height: "98vh",
        display: "flex",
        justifyContent: "center",
        overflow: "auto"
      }}
    >
      <Box sx={{ width: "70vw" }}>
        <Box sx={{ marginBottom: "20px", marginTop: "40px" }}>
          <Typography sx={{ fontWeight: "700", fontSize: "2rem" }} variant="h6">
            Add Buckets
          </Typography>
        </Box>
        <form>
          <Grid container spacing={3}>
    
         <Grid item xs={6}>
              <TextField
                value={captionText}
                onChange={(e) => {
                  setCaptionText(e.target.value);
                }}
                label="Caption"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
               <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>Display State</InputLabel>
                <Select
                  multiple
                  value={displayStateId}
                  onChange={(e) => {
                    setDisplayStateID(e.target.value);
                  }}
                  label="Display State"
                >
                   {stateList.map((state, index) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.state}
                    </MenuItem>
                  ))} 
                </Select>
              </FormControl> 
            </Grid>
          

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  if (
                    captionText === "" || displayStateId.length ===0
                  ) {
                    showAlert("Kindly fill all required fields!", "error");
                    return;
                  }
                  uploadVideo(
                    captionText,
                    displayStateId
                  );
                }}
                variant="contained"
                color="primary"
              >
                {!loading ? "Submit" : "Uploading..."}
              </Button>
            </Grid>
          </Grid>
        </form>
        <BucketTable stateList={stateList}/>     
        </Box>
        
    </Box>
  );
};

export default AddEditBuckets;
