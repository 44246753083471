const styles = {
  optionsSection: {
    width: 452,
    height: 46,
    left: 205,
    top: 152,
    background: "#FFFFFF",
    border: "1px solid #3B5998",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    marginTop: 25,
  },
  selectedStatusPending: {
    backgroundColor: "#E8BD0D !important",
    color: "#FFF !important",
  },
  selectedStatusApproved: {
    backgroundColor: "#1FAA59 !important",
    color: "#FFF !important",
  },
  selectedStatusRejected: {
    backgroundColor: "#FF5252 !important",
    color: "#FFF !important",
  },
  normalButtonPending: {
    color: "#E8BD0D !important",
    borderColor: "#E8BD0D !important",
  },
  normalButtonApproved: {
    color: "#1FAA59 !important",
    borderColor: "#1FAA59 !important",
  },
  normalButtonRejected: {
    color: "#FF5252 !important",
    borderColor: "#FF5252 !important",
  },
  iconPending: {
    color: "#E8BD0D !important",
  },
  iconApproved: {
    color: "#1FAA59 !important",
  },
  iconRejected: {
    color: "#FF5252 !important",
  },
  iconSelected: {
    color: "#FFF !important",
  },
  optionClass: {
    width: 214,
    height: 40,
    left: 209,
    top: 156,
    borderRadius: 30,
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center !important",
    cursor: "pointer",
    transition: "background 0.3s ease-in-out",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: "0.02em",
    textTransform: "uppercase",
  },
  topbarSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "30px",
  },
  topbarButton: {
    width: "220px",
    height: "56px",
    borderRadius: "4px",
    padding: "10px 12px 10px 12px",
    marginRight: "12px !important",
    fontFamily: "Public Sans",
    fontSize: "18px",
    lineHeight: "21.15px",
    fontWeight: "700",
    fontStyle: "normal",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#FFF",
    borderRadius: 4,
    transition: "background 1s ease-in-out",
    backgroundColor: "#FFF",
    "&.MuiButton-contained": {
      background: "#3B5998",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(50, 50, 105, 0.15)",
      "&:hover": {
        background: "#3B5998",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(50, 50, 105, 0.15)",
      },
    },
    "&.MuiButton-outlined": {
      borderColor: "#3B5998",
      color: "#3B5998",
    },
  },
  topbarButtonIcon: {
    marginRight: "10px",
  },
};

export default styles;
