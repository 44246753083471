import React, { useContext, useEffect, useState } from "react";

// MUI
import { AddCircleOutline, Close, Search } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// styles
import styles from "./styles";

// packages
import ReactPlayer from "react-player/lazy";
import moment from "moment";

// components
import AddNewTagCard from "components/addNewTag";
import ButtonInput from "components/input/Button";
import ConfirmationDialog from "components/confirmationDialog";
import { getAllCounts } from "pages/uploadedPosts/actions";

// contexts
import { AlertContext } from "contexts/AlertContext";

const PostCard = (props) => {
  const { classes } = props;

  const [selectedTaggedCategory, setSelectedTaggedCategory] =
    useState("Campaigns");
  const [taggedOptions, setTaggedOptions] = useState([]);
  const [openAddNewTagCard, setOpenAddNewTagCard] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [tagCategories, setTagCategories] = useState([]);
  const [state_tag_id, setStateTagId] = useState(null);
  const [lang_tag_id, setLangTagId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [captionValue, setCaptionValue] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [] = useState("");
  const [highlighted, setHighlighted] = useState(0);

  const { showAlert } = useContext(AlertContext);

  const selectTag = (tag) => {
    // debugger;
    if (selectedTaggedCategory === "Language") {
      setLangTagId(tag);
    } else if (selectedTaggedCategory === "State") {
      setStateTagId(tag);
    } else {
      setTaggedOptions((oldTaggedOptions) => {
        const index = oldTaggedOptions.findIndex(
          (oldOption) => oldOption.id === tag.id
        );
        if (index >= 0) {
          oldTaggedOptions = oldTaggedOptions?.filter(
            (oldOption) => oldOption.id !== tag.id
          );
          return oldTaggedOptions;
        } else {
          return [...oldTaggedOptions, tag];
        }
      });
    }
  };

  const setAlreadyTaggedOptions = () => {
    if (!props.alreadyTaggedOptions[props.post.post_id]) {
      return;
    }
    const otherTags = [];
    for (let category in props.tagsData) {
      for (let j = 0; j < props.tagsData[category].length; j++) {
        if (
          !!props.alreadyTaggedOptions[props.post.post_id].find(
            (tagId) => props.tagsData[category][j].id === tagId
          )
        ) {
          if (category === "Language") {
            setLangTagId(props.tagsData[category][j]);
          } else if (category === "State") {
            setStateTagId(props.tagsData[category][j]);
          } else {
            otherTags.push(props.tagsData[category][j]);
          }
        }
      }
    }
    setTaggedOptions(otherTags);
  };

  // const dragOver = (event) => {
  //   event.preventDefault();
  //   postType !== "Approved" && setIsDragOver(true);
  // };
  // const dragEnter = (event) => {
  //   event.preventDefault();
  // };
  // const dragLeave = (event) => {
  //   event.preventDefault();
  //   postType !== "Approved" && setIsDragOver(false);
  // };
  // const fileDrop = (event, isBrowse = false) => {
  //   event.preventDefault();
  //   if (postType !== "Approved") {
  //     setIsDragOver(false);
  //     if (isBrowse) {
  //       if (!event.target.files[0]) {
  //         return;
  //       }
  //       setFile(event.target.files[0]);
  //     } else {
  //       if (!event.dataTransfer.files[0]) {
  //         return;
  //       }
  //       setFile(event.dataTransfer.files[0]);
  //     }
  //   }
  // };
  // const handleCancel = () => {
  //   setFile(null);
  //   setPreview(null);
  // };

  const removeSelectedTag = (tag) => {
    if (props.postType === "Approved") {
      return;
    }
    if (lang_tag_id && lang_tag_id.id === tag.id) {
      setLangTagId(null);
    } else if (state_tag_id && state_tag_id.id === tag.id) {
      setStateTagId(null);
    } else {
      setTaggedOptions((oldTaggedOptions) =>
        oldTaggedOptions?.filter((taggedOption) => tag.id !== taggedOption.id)
      );
    }
  };

  const handleChange = (event) => {
    setInputVal(event.target.value);
    // setTagCategories(() =>
    //   props.tagsData[selectedTaggedCategory]
    // );
  };

  // const dataURLtoFile = (dataurl, filename) => {
  //   let arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // };

  const editPost = async () => {
    let caption = captionValue;
    if (!caption.trim().length) {
      caption = " ";
    }
    try {
      let thumbnail_url = null;
      if (props.post.post_type === "Video") {
        thumbnail_url = props.post.thumbnail_url;
      }
      const response = { data: null, error: null };
      const { data, error } = await props.handleEditPost(
        props.post.post_img_vid_url,
        caption,
        props.post.post_type,
        props.post.post_id,
        thumbnail_url
      );
      if (error) {
        return "error";
      } else {
        return data;
      }
    } catch (error) {
      console.log("Error", error.message);
      showAlert(
        "Something went wrong while tagging! Please try again.",
        "error"
      );
      throw new Error(error);
    }
  };

  useEffect(() => {
    setAlreadyTaggedOptions();
    setCaptionValue(props.post.caption);
    // setTagCategories(props.tagsData[selectedTaggedCategory]);
  }, []);

  const onClickApproved = async () => {
    try {
      if (!(lang_tag_id)) {
        showAlert("Please select at least one language tag", "error"); 
        return;
      }
      setLoading(true);
      const result = await editPost();
      const verifyTags = [];
      const pendingTags = [];
      for (let indx = 0; indx < taggedOptions.length; indx++) {
        if (taggedOptions[indx]?.status === "Pending") {
          pendingTags.push(taggedOptions[indx].id);
        } else {
          verifyTags.push(taggedOptions[indx].id);
        }
      }
      if(typeof result !== "string") {
        await props.handlePostApproved(
          props.post.post_id,
          props.post.post_type,
          state_tag_id===null?0:state_tag_id?.id,
          lang_tag_id.id,
          verifyTags,
          pendingTags,
          result.vp9,
          highlighted
        );
        showAlert("Successfully submitted the post", "success");
        const formattedDate = props.formatDate(props.date);
        const response = await getAllCounts(
          formattedDate.startDate,
          formattedDate.endDate,
          props.userState
        );
        if(!result.vp9) {
          await props.getPosts(props.date, props.offset, props.postType);
          await props.setPostsCounts(response.data);
          showAlert("Post submitted successfully", "success");
        } else {
          showAlert("Post submitted successfully but will take some time to reflect", "success");
        }
      }
    } catch (error) {
      console.log("Error", error.message);
      showAlert("Something went wrong! Please try again.", "error");
    }
    setLoading(false);
  };

  const handleRejectClick = () => {
    setShowConfirm(true);
  };

  const handleReject = async () => {
    setRejectLoading(true);
    await props.handleRejectPost(props.post.post_id);
    setRejectLoading(false);
    setShowConfirm(false);
    const formattedDate = props.formatDate(props.date);
    const response = await getAllCounts(
      formattedDate.startDate,
      formattedDate.endDate,
      props.userState
    );
    props.setPostsCounts(response.data);
    props.setPosts((posts) =>
      posts?.filter((post) => post.post_id !== props.post.post_id)
    );
    props.setFilteredPosts((posts) =>
      posts?.filter((post) => post.post_id !== props.post.post_id)
    );
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleCategorySelect = (tagCategory) => {
    setSelectedTaggedCategory(tagCategory);
    setTagCategories(() =>
      props.tagsData[tagCategory] && props.tagsData[tagCategory].length
        ? props.tagsData[tagCategory]
        : []
    );
    setInputVal("");
  };

  return (
    <div className={classes.cardSection}>
      <ConfirmationDialog
        open={showConfirm}
        handleClose={handleClose}
        title="Are you sure to reject this post?"
        handleReject={handleReject}
        loading={rejectLoading}
      />
      {openAddNewTagCard && (
        <AddNewTagCard
          openAddNewTagCard={openAddNewTagCard}
          setOpenAddNewTagCard={setOpenAddNewTagCard}
          getTagCatagories={props.getTagCatagories}
        />
      )}
      <Box>
        {/* <Typography className={classes.postTitle}>
          #Post {props.postNumber}
        </Typography> */}
        <TextField
          className={classes.bannerInput}
          variant="outlined"
          width="100%"
          fullWidth
          multiline
          rows={2}
          defaultValue={captionValue}
          disabled={props.postType === "Approved"}
          spellCheck
          onKeyUp={(event) => setCaptionValue(event.target.value)}
        />
      </Box>
      <Box className={classes.contentSection}>
        <Box className={classes.leftSection}>
          <Box className={classes.leftTopSection}>
            {props.post.post_type === "Image" ? (
              <img
                src={props.post.post_img_vid_url}
                className={classes.cardImageContent}
              />
            ) : (
              <ReactPlayer
                className={classes.video}
                url={props.post.post_img_vid_url}
                controls
                width="280px"
                height="280px"
                playing={false}
                light={<img src={props.post.thumbnail_url} alt="Thumbnail" />}
                loop
                muted
                config={{
                  playerOptions: {
                    playsinline: true,
                  },
                  facebook: {
                    attributes: {
                      'data-width': '280px',
                      'data-height': '280px'
                    }
                  }
                }}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <Box className={classes.detailSection}>
              <Box className={classes.titleName}>Page Name</Box>
              <Box className={classes.valueData}>: {props.post.page_name}</Box>
            </Box>
            <Box className={classes.detailSection}>
              <Box className={classes.titleName}>Posted on FB</Box>
              <Box className={classes.valueData}>
                : {moment(props.post.posted_on_source).format("LLL")}
              </Box>
            </Box>
            <Box className={classes.detailSection}>
              <Box className={classes.titleName}>Fetched On</Box>
              <Box className={classes.valueData}>
                : {moment(props.post.updated_at).format("LLL")}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightSection}>
          <Box className={classes.tagsSection}>
            <Box className={classes.tagsLeftSection}>
              <Box
                className={classes.taggedSection}
                style={{ minHeight: "345px", width: "45%" }}
              >
                <Box className={classes.tagSectionTop}>Categories</Box>
                <Box className={classes.taggedCategorySection}>
                  {Object.keys(props.tagsData).map((tagCategory, index) => (
                    <Typography
                      className={`${classes.tagCategoryTitle} ${
                        selectedTaggedCategory === tagCategory
                          ? classes.tagCategoryTitleColor
                          : classes.fontWeight400
                      } ${classes.cursorPointer}`}
                      sx={{ marginBottom: "10px" }}
                      key={index}
                      onClick={() => handleCategorySelect(tagCategory)}
                    >
                      {tagCategory}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box
                className={classes.taggedSection}
                style={{ minHeight: "345px", width: "55%" }}
              >
                <div className={classes.tagSectionTop}>Lists</div>
                <div className={classes.inputSection}>
                  <Search sx={{ marginTop: "2px" }} />
                  <input
                    className={classes.inputSearch}
                    value={inputVal}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.tagListSection}>
                  {props.tagsData?.[selectedTaggedCategory]
                    ?.filter((category) => {
                      if (inputVal.trim() === "") {
                        return category;
                      } else {
                        return category.name
                          .toLowerCase()
                          .includes(inputVal.toLowerCase());
                      }
                    })
                    .map((listName, index) =>
                      listName.status !== "Rejected" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignContent: "center",
                            marginBottom: "10px !important",
                            padding: "5px",
                          }}
                          key={index}
                        >
                          <Typography
                            className={`${classes.tagCategoryTitle} ${
                              [
                                state_tag_id,
                                lang_tag_id,
                                ...taggedOptions,
                              ].find((tag) => tag && tag.id === listName.id)
                                ? classes.tagCategoryTitleColor
                                : classes.fontWeight400
                            }  ${
                              props.postType === "Approved"
                                ? classes.cursorNotAllowed
                                : classes.cursorPointer
                            }`}
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                            }}
                            key={listName.id}
                            onClick={() =>
                              props.postType !== "Approved" &&
                              selectTag(listName)
                            }
                          >
                            {listName.name}
                          </Typography>
                          {listName.status === "Pending" && (
                            <Box
                              sx={{
                                color: "#FFF",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                marginLeft: "10px",
                                backgroundColor: "#EBB800",
                                padding: "4px 6px",
                                borderRadius: "20px",
                              }}
                            >
                              Pending{" "}
                              <Tooltip
                                title="This is a pending tag"
                                placement="top"
                                arrow
                              >
                                <InfoIcon
                                  sx={{
                                    width: "0.9rem",
                                    height: "0.9rem",
                                    marginLeft: "3px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      ) : null
                    )}
                </div>
              </Box>
            </Box>
            <Box
              className={`${classes.taggedSection} ${classes.taggedAddSection}`}
            >
              <div className={classes.tagSectionTop}>Selected Tags</div>
              <div
                className={`${classes.selectedTaggedOptionsSection} ${
                  !(taggedOptions.length || state_tag_id || lang_tag_id) &&
                  classes.alignNoContentText
                }`}
              >
                {taggedOptions.length || state_tag_id || lang_tag_id ? (
                  [state_tag_id, lang_tag_id, ...taggedOptions].map(
                    (tag, index) => {
                      if (tag) {
                        return (
                          <div className={classes.taggedOption} key={tag.id}>
                            {tag.name}
                            <Close
                              className={`${classes.closeIcon} ${
                                props.postType === "Approved"
                                  ? classes.cursorNotAllowed
                                  : classes.cursorPointer
                              }`}
                              onClick={() => removeSelectedTag(tag)}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                  )
                ) : (
                  <Box className={classes.graishColor}>
                    No Tags Selected Yet
                  </Box>
                )}
              </div>
            </Box>
          </Box>
          <Box className={classes.rightBottomSection}>
            <Box
              className={`${classes.rightBottomInnerSection} ${classes.justifyContentEnd}`}
            >
             
              {props.postType !== "Approved" && (
                <Box
                 sx={{display:'flex', alignItems:'center', height:'43px', gap:'8px', color:'rgba(120, 117, 117, 1)', cursor:'pointer' }}
                  onClick={() =>{}}
                >
                  <input type="checkbox" onChange={(val)=>{setHighlighted(val.target.checked?1:0)}} style={{transform:'scale(1.3)'}}/>
                  Featured
                </Box>
              )}



              {props.postType !== "Approved" && (
                <Box
                  className={classes.addNewTagButtonSection}
                  onClick={() => setOpenAddNewTagCard(true)}
                >
                  <AddCircleOutline sx={{ fontSize: 14, color: "#3B5998" }} />
                  Add new tag
                </Box>
              )}
              {(props.postType === "Pending" ||
                props.postType === "Approved") && (
                <Box className={classes.rejectBtn} onClick={handleRejectClick}>
                  Reject
                </Box>
              )}
              {props.postType !== "Approved" && (
                <Box style={{ cursor: "pointer" }}>
                  <ButtonInput
                    className={classes.submitButtonSection}
                    onClick={onClickApproved}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "#FFF" }} />
                    ) : (
                      "Submit"
                    )}
                  </ButtonInput>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withStyles(styles)(PostCard);
