import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  getBucketListAPI,
  getLangListAPI,
  getPageListAPI,
  getStateListAPI,
  uploadDataAPI,
} from "./actions";
import { AlertContext } from "contexts/AlertContext";
import ReactVirtualizedTable from "./UploadHistory";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateVideo = () => {
  const { setActivePage } = React.useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  const [stateList, setStateList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [bucketList, setBucketList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [captionText, setCaptionText] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [stateId, setStateID] = useState(-1);
  const [displayStateId, setDisplayStateID] = useState([]);
  const [langId, setLangID] = useState(-1);
  const [bucketId, setBucketID] = useState(-1);
  const [pageId, setPageId] = useState(-1);
  const [loading, setLoading] = useState(false);
  
  const [uploadedImage, setUploadedImage] = useState();
  const [tempPath, setTempPath] = useState();

  const getStateList = async () => {
    try {
      const { data, error } = await getStateListAPI();

      console.log(data);
      if (data === null) {
        setStateList([]);
      } else {
        setStateList(data.state);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const getLangList = async () => {
    try {
      const { data, error } = await getLangListAPI();

      console.log(data);
      if (data === null) {
        setLangList([]);
      } else {
        setLangList(data.lang);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const getBucketList = async () => {
    try {
      const { data, error } = await getBucketListAPI();

      console.log(data);
      if (data === null) {
        setBucketList([]);
      } else {
        setBucketList(data);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const getPageList = async () => {
    try {
      const { data, error } = await getPageListAPI();

      console.log(data);
      if (data === null) {
        setPageList([]);
      } else {
        setPageList(data);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const uploadVideo = async (
    url,
    caption,
    page_id,
    language_id,
    thumbnail_url,
    bucket_id,
    state_id
  ) => {
    setLoading(true);
    try {
      const { data, error } = await uploadDataAPI(
        url,
        caption,
        page_id,
        language_id,
        uploadedImage,
        bucket_id,
        state_id
      );

      if (error) {
        showAlert(error, "error");
      } else {
        setBucketID(-1);
        setCaptionText("");
        setPageId(-1);
        setMediaUrl("");
        setLangID(-1);
        setUploadedImage(null);
        setStateID(-1);
        showAlert("Uploaded Successfully!", "success");
      }
      setLoading(false);
    } catch (error) {
      showAlert(error, "error");
    }
  };

 const handleCapture = ({ target }) => {
   console.log(target.files[0])
   setUploadedImage(target.files[0])
   setTempPath(URL.createObjectURL(target.files[0]));
  
};

  useEffect(() => {
    getStateList();
    getLangList();
    getPageList();
    getBucketList();
  }, []);
  const classes = useStyles();

  return (
    <Box
      sx={{
        height: "98vh",
        display: "flex",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box sx={{ width: "72vw" ,
        marginRight: 4}}>
        <Box sx={{ marginBottom: "20px", marginTop: "40px" }}>
          <Typography sx={{ fontWeight: "700", fontSize: "2rem" }} variant="h6">
            Upload Content
          </Typography>
        </Box>
        <Box sx={{display:'flex', gap:2}}>
        <Box sx={{height:295, minWidth:300, width:300, border:'1px solid grey'}}>
                <img src={tempPath} style={{objectFit:'contain'}} />
              </Box>
              <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                value={captionText}
                onChange={(e) => {
                  setCaptionText(e.target.value);
                }}
                label="Caption"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>Category</InputLabel>
                <Select
                  value={bucketId}
                  onChange={(e) => {
                    setBucketID(e.target.value);
                  }}
                  label="Category"
                >
                  <MenuItem key={-1} value={-1}>
                    Select Category
                  </MenuItem>
                  {bucketList.map((bucket, index) => (
                    <MenuItem key={bucket.bucket_id} value={bucket.bucket_id}>
                      {bucket.bucket_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>State</InputLabel>
                <Select
                  value={stateId}
                  onChange={(e) => {
                    setStateID(e.target.value);
                  }}
                  label="State"
                >
                  <MenuItem key={-1} value={-1}>
                    Select State
                  </MenuItem>
                  <MenuItem key={"24"} value={"24"}>
                      {"India"}
                    </MenuItem>
                  {stateList.map((state, index) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>Display State</InputLabel>
                <Select
                  multiple
                  value={displayStateId}
                  onChange={(e) => {
                    setDisplayStateID(e.target.value);
                  }}
                  label="Display State"
                >
                  {/* <MenuItem key={"24"} value={"24"}>
                      {"India"}
                    </MenuItem> */}
                  {/* {stateList.map((state, index) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.state}
                    </MenuItem>
                  ))} */}
                {/* </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>Language</InputLabel>
                <Select
                  value={langId}
                  onChange={(e) => {
                    setLangID(e.target.value);
                  }}
                  label="Language"
                >
                  <MenuItem key={-1} value={-1}>
                    Select Language
                  </MenuItem>
                  {langList.map((lang, index) => {
                    if (lang.is_active) {
                      return (
                        <MenuItem key={lang.id} value={lang.id}>
                          {lang.language}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel>Page ID</InputLabel>
                <Select
                  value={pageId}
                  onChange={(e) => {
                    setPageId(e.target.value);
                  }}
                  label="Page ID"
                >
                  <MenuItem key={-1} value={-1}>
                    Select Page
                  </MenuItem>
                  {pageList.map((page, index) => (
                    <MenuItem key={page.page_id} value={page.page_id}>
                      {page.page_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={mediaUrl}
                onChange={(e) => {
                  setMediaUrl(e.target.value);
                }}
                label="Media URL"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sx={{display:'flex', gap:2, alignItems:'center'}}>
             
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                onChange={handleCapture}
                type="file"
              />
              <label htmlFor="raised-button-file">

                <Button
                 
                  variant="outlined"
                  component="span"
                  className={classes.button}
                >
                  Upload Thumbnail Image
                </Button>
              </label>
            
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  if (
                    stateId === -1 ||
                    langId === -1 ||
                    bucketId === -1 ||
                    pageId === -1 ||
                    captionText === "" ||
                    mediaUrl === "" ||
                    uploadedImage === null
                  ) {
                    showAlert("Kindly fill all required fields!", "error");
                    return;
                  }
                  uploadVideo(
                    mediaUrl,
                    captionText,
                    pageId,
                    langId,
                    thumbnailUrl,
                    bucketId,
                    stateId
                  );
                }}
                variant="contained"
                color="primary"
              >
                {!loading ? "Submit" : "Uploading..."}
              </Button>
            </Grid>
          </Grid>
        </form>     
        </Box>
      
        <ReactVirtualizedTable pageList={pageList} stateList={stateList} langList ={langList}/>
        



      </Box>
    </Box>
  );
};

export default CreateVideo;
