import React, { useState, useEffect, useContext } from "react";

// MUI
import { TextField, Tooltip, Box } from "@mui/material";
import { EditSharp } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { withStyles } from "@mui/styles";
import MenuBookIcon from "@mui/icons-material/MenuBook";

// components
import TagStatus from "./TagStatus";
import InforCard from "./InforCard";

// styles
import styles from "../styles";
import { editTagName, fetchPostsByTag } from "../actions";
import { AlertContext } from "contexts/AlertContext";

// styles props
const InputLabelProps = {
  style: {
    height: "30px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
};

const inputProps = {
  style: {
    height: "30px",
    padding: "0 14px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
};

const RowConent = ({
  classes,
  column,
  row,
  handleApprovedReject,
  postType,
  handleEdit,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [tagNameValue, setTagNameValue] = useState("");
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [infoData, setInfoData] = useState([]);

  const { showAlert } = useContext(AlertContext);

  const handleChange = (event) => {
    setTagNameValue(event.target.value);
  };

  const onKeyChange = async (event) => {
    if (event.key === "Enter") {
      try {
        if (!tagNameValue?.trim()) {
          showAlert("Please add some text", "error");
          return;
        }
        await handleEdit(row.rowId, tagNameValue);
        setIsEditable(false);
        showAlert("Tagname changed successfully", "success");
      } catch (error) {
        if (error.message.includes("400")) {
          showAlert("This tag name is already present.", "error");
        } else {
          showAlert("Something went wrong", "error");
        }
      }
    }
  };

  const handleEditTagName = async () => {
    try {
      if (!tagNameValue?.trim()) {
        showAlert("Please add some text", "error");
        return;
      }
      await handleEdit(row.rowId, tagNameValue);
      setIsEditable(false);
      showAlert("Tagname changed successfully", "success");
    } catch (error) {
      if (error.message.includes("400")) {
        showAlert("This tag name is already present.", "error");
      } else {
        showAlert("Something went wrong", "error");
      }
    }
  };

  useEffect(() => {
    setTagNameValue(row.tag_name);
  }, []);

  const handleClickInfo = async () => {
    try {
      if (!showInfoCard) {
        const { data } = await fetchPostsByTag(row.rowId);
        if (!data?.length || !data[0]?.length) {
          showAlert("No posts found for this tag", "error");
          return;
        }
        setInfoData(data);
      } else {
        setInfoData([]);
      }
      setShowInfoCard(!showInfoCard);
    } catch (error) {
      showAlert("No posts found for this tag", "error");
    }
  };

  return (
    <React.Fragment>
      {showInfoCard && (
        <InforCard
          role="tooltip"
          show={showInfoCard}
          setShow={(show) => setShowInfoCard(show)}
          data={infoData}
          setInfoData={setInfoData}
        />
      )}

      {column.name !== "action" ? (
        column.name === "tag_name" ? (
          <Box className={classes.tableCellSection}>
            {isEditable ? (
              <Box>
                <TextField
                  value={tagNameValue}
                  autoComplete="off"
                  onChange={(event) => handleChange(event)}
                  onKeyUp={(event) => onKeyChange(event)}
                  spellCheck
                  className={classes.editableTextField}
                  InputLabelProps={InputLabelProps}
                  inputProps={inputProps}
                  rows={1}
                />
              </Box>
            ) : (
              <Box sx={{ marginRight: "10px" }}>{tagNameValue}</Box>
            )}
            {"  "}
            {isEditable && (
              <>
                <Tooltip title="Click to discard">
                  <ClearIcon
                    sx={{ color: "#FF5252" }}
                    className={classes.icon}
                    onClick={() => {
                      setTagNameValue(row.tag_name);
                      setIsEditable(false);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Click to Edit Tag">
                  <DoneIcon
                    sx={{ color: "#1FAA59" }}
                    className={classes.icon}
                    onClick={handleEditTagName}
                  />
                </Tooltip>
              </>
            )}
            {!isEditable && (
              <EditSharp
                onClick={() => setIsEditable(true)}
                className={classes.editIcon}
              />
            )}
            <Tooltip title="Click to show info">
              <MenuBookIcon
                className={classes.infoIcon}
                onClick={handleClickInfo}
              />
            </Tooltip>
          </Box>
        ) : (
          <>{row[column.name]}</>
        )
      ) : (
        <TagStatus
          rowId={row.rowId}
          isApproved={row.isApproved}
          handleApprovedReject={handleApprovedReject}
          postType={postType}
        />
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(RowConent);
