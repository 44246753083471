import { useState, createContext } from "react";

export const AlertContext = createContext();

export const AlertProvider = (props) => {
  const [msg, setMsg] = useState(null);
  const [severity, setSeverity] = useState(null);

  const showAlert = (msg, severity) => {
    setSeverity(severity);
    setMsg(msg);
  };

  const hideAlert = () => {
    setMsg(null);
    setSeverity(null);
  };

  const state = {
    msg,
    severity,
  };

  return (
    <AlertContext.Provider
      value={{
        msg: state.msg,
        severity: state.severity,
        showAlert,
        hideAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
