const styles = {
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bolder",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#1B1C1E",
    width: "540px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    // border: "1px solid red",
    height: "40px",
    paddingLeft: "10px",
    fontFamily: "Public Sans",
    fontStyle: "normal",
  },
  mainSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    // marginTop: "16px",
  },
  leftPortion: {
    width: "40%",
    borderRight: "1px solid #E7F2FF",
    fontFamily: "Public Sans",
    fontStyle: "normal",
  },
  headerSection: {
    background: "#2a2d32",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFF",
    // borderRadius: "10px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    letterSpacing: "0.02em",
    height: "42px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    alignContent: "center",
    fontFamily: "Public Sans",
    fontStyle: "normal",
  },
  optionsSection: {
    paddingLeft: "10px",
    paddingTop: "10px",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.02em",
    fontFamily: "Public Sans",
    fontStyle: "normal",
  },
  rightPortion: {
    width: "60%",
  },
  rightBottomPortion: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "30px",

    padding: "0 20px",
    width: "100%",
    right: 0,
    fontFamily: "Public Sans",
    fontStyle: "normal",
  },
  dialogSection: {
    width: "540px",
    height: "500px",
    overflow: "hidden",
    zIndex: "9999999999999999 !important",
    margin: "auto",
  },
  dialogMainSection: {
    height: "400px",
    background: "#FFFFFF",
    border: "1px solid #E5E5E5 !important",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    overflow: "hidden",
  },
  addNewTagTextField: {
    marginTop: "10px !important",
    paddingLeft: "10px !important",
    width: "90% !important",
    input: {
      "&::placeholder": {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#2a2d32  !important",
        letterSpacing: "0.02em",
      },
      "&:focus": {
        border: "1px solid #2a2d32 !important",
      },
    },
  },
  category: {
    marginBottom: "10px",
    cursor: "pointer",
    borderRadius: "25px",
    padding: "5px 0 5px 7px",
    transition: "all 0.5s ease-in-out",
    backgroundColor: "transparent",
    color: "#303030",
    fontWeight: 400,
  },
  selectedCategory: {
    backgroundColor: "#cacaca",
    color: "#2a2d32",
    fontWeight: 700,
  },
};

export default styles;
