import React from "react";

// MUI
import { Box } from "@mui/material";

// assets
import NoAccessDeniedImage from "assets/images/no-access-denied.avif";

const UnAuthorized = () => {
  return (
    <Box sx={{ height: "80vh", display: "flex", justifyContent: "center" }}>
      <img
        style={{ width: "500px", height: "400px", margin: "auto" }}
        src={NoAccessDeniedImage}
      />
    </Box>
  );
};

export default UnAuthorized;
