import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import Loading from "pages/analytics/loader/LoadingAnim";
import { getAdminBucketListAPI, getBucketListAPI, getUploadHistory } from "./actions";
import SelectCategory from "./SelectPage";
import SelectLang from "./SelectLang";
import CaptionEdit from "./CaptionEdit";
import SelectState from "./SelectState";
import SelectActive from "./SelectActive";
import CaptionEditBucket from "./CaptionEditBucket";
import SelectActiveBucket from "./SelectActiveBucket";
import SelectStateBucket from "./SelectStateBucket";

const columns = [
  {
    id: "bucket_id",
    label: "Bucket ID",
    minWidth: 80,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "bucket_name",
    label: "Bucket Name",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state_ids",
    label: "State",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "is_active",
    label: "Active/ Inactive",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(bucket_id, bucket_name, state_ids, is_active) {
  return {
    bucket_id,
    bucket_name,
    state_ids,
    is_active,
  };
}

export default function BucketTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [date, setDate] = useState([
    moment().subtract(7, "d")._d,
    moment().subtract(1, "d")._d,
  ]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getContentSupplyData = async (date) => {
    setRows([]);
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getAdminBucketListAPI();
      if (data === null) {
        setRows([]);
      } else {
        let rowData = [];
        data?.data?.forEach((tablerow, index) => {
          rowData.push(
            createData(
              tablerow.bucket_id,
              tablerow.bucket_name,
              tablerow.statenames.join(", ") ?? "",
              tablerow.is_active? "Active" : "Inactive"
            )
          );
        });
        setRows(rowData);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      getContentSupplyData([
        moment().subtract(7, "d")._d,
        moment().subtract(1, "d")._d,
      ]);
    }
  }, []);

  return rows.length === 0 ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>
  ) : (
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          marginBottom: "20px",
          marginTop: "40px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "700", fontSize: "2rem" }} variant="h6">
          Edit Buckets
        </Typography>
        <Button
          onClick={getContentSupplyData}
          variant="outlined"
          component="span"
        >
          Refresh
        </Button>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 460 }}>
          <div id="content_supply">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      sx={{
                        backgroundColor: "rgba(209, 230, 255, 1)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                      }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                              }}
                            >
                              {column.id === "bucket_name" ? (
                                <CaptionEditBucket
                                  value={value}
                                  post_id={row["bucket_id"]}
                                  is_active={row["is_active"] === "Active" ? 1 : 0}
                                />
                              ) : column.id === "is_active" ? (
                                <SelectActiveBucket
                                  value={value}
                                  post_id={row["bucket_id"]}
                                  is_active={row["is_active"] === "Active" ? 1 : 0}
                                />
                              ) : column.id === "state_ids" ? (
                                <SelectStateBucket
                                  value={value}
                                  stateList={props.stateList}
                                  post_id={row["bucket_id"]}
                                  is_active={row["is_active"] === "Active" ? 1 : 0}
                                />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Paper>
    </Box>
  );
}
