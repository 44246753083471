import { Edit } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import './EditStyle.css'

import { AlertContext } from "contexts/AlertContext";
import { editCaptionFeatured } from "./actions";

const SelectState = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [stateId, setStateID] = useState(-1);
    const [stateName, setStateName] = useState(props.value);
    const [stateList, setStateList] = useState(props.stateList
    );

    const { showAlert } = useContext(AlertContext);
    const handleStateUpdate = async (state_id) => {
      const { data, error } = await editCaptionFeatured(props.post_id, props.is_active, undefined, undefined, undefined, state_id);
      if(data){
        showAlert("Updated Sucessfully!", "success");
        for( let i = 0; i< stateList.length; i++){
          if(stateList[i].id === state_id){
            setStateName(stateList[i].state)
          }
      }
      }
      if(error){
        showAlert("Something Went Wrong!", "error");
      }
      setEditMode(false);
    };

    useEffect(() => {}, [editMode]);
    return (
      <>
        {!editMode ? (
           <div
           className="language-display"
           onClick={() => {
               setEditMode(true);
               
           }}
       >
           {stateName}
           <Edit className="edit-icon" />
       </div>
   ) : (
    <FormControl
  >
    <InputLabel>State</InputLabel>
    <Select
      value={stateId}
      onChange={(e) => {
        setStateID(e.target.value);
        handleStateUpdate(e.target.value);    
      }}
      label="State"
    >
      <MenuItem key={-1} value={-1}>
        Select State
      </MenuItem>
      <MenuItem key={"24"} value={"24"}>
          {"India"}
        </MenuItem>
      {stateList.map((state, index) => (
        <MenuItem key={state.id} value={state.id}>
          {state.state}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
        )}
      </>
    );
  };

export default SelectState
