import React from "react";

// MUI
import { Box, Skeleton, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";

// styles
import styles from "./styles";

const PostCardSkeleton = (props) => {
  const { classes } = props;
  return (
    <Stack spacing={1} className={classes.skeletonBody}>
      <Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={70}
          sx={{ marginBottom: "10px" }}
        />
      </Box>
      <Box className={classes.skeletonLeftSection}>
        <Box className={classes.skeletonImage}>
          <Box sx={{ marginBottom: "20px" }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={250}
              sx={{ borderRadius: "10px" }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width="100%"
              height="150px"
              sx={{ borderRadius: "10px" }}
            />
          </Box>
        </Box>
        <Box className={classes.skeletonRightSection}>
          <Box>
            <Skeleton
              variant="rounded"
              width="100%"
              height="350px"
              sx={{ marginBottom: "30px" }}
            />
          </Box>
          <Box className={classes.skeletonRightBottom}>
            <Box className={classes.skeletonRightBottomInner}>
              <Skeleton variant="rounded" width="100px" height="40px" />
              <Skeleton variant="rounded" width="100px" height="40px" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default withStyles(styles)(PostCardSkeleton);
