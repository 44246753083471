// packages
import AXIOS from "services/axios";

// utils
import { getConfig } from "utils/config";

export const getTagsCounts = async (startDate, endDate) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const res = await AXIOS.get(
      `v1/tags/status_wise_count?start_date=${startDate}&end_date=${endDate}`,
      config
    );
    response.data = res.data;
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getTagsList = async (
  status = "Pending",
  startDate,
  endDate,
  limit,
  offset
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.get(
      `v1/tags/${status}/list?start_date=${startDate}&end_date=${endDate}&limit=${limit}&offset=${offset}`,
      config
    );
    if (error) {
      throw new Error(error);
    }
    response.data = data.data;
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

export const fetchPostsByTag = async (tagId) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.get(
      `v1/tags/${tagId}/fetch_posts`,
      config
    );
    if (error) {
      throw new Error(error);
    }
    response.data = data.data;
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

export const editTagName = async (tagId, tag_name) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.put(
      `v1/tags/edit/${tagId}`,
      { tag_name },
      config
    );
    if (error) {
      response.error = error;
      throw new Error(error);
    }
    response.data = data.data;
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

export const verifyTags = async (rejected_tags, approved_tags) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data, error } = await AXIOS.put(
      `v1/tags/verify/`,
      { rejected_tags, approved_tags },
      config
    );
    if (error) {
      response.error = error;
      throw new Error(error);
    }
    response.data = data.data;
  } catch (error) {
    throw new Error(error);
  }
  return response;
};
