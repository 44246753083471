const styles = {
  tagVerificationSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  verificationTop: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px",
    paddingTop: "15px",
    flexWrap: "wrap",
  },
  tableName: {
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.02em",
    color: "#303030",
    marginTop: "20px",
    marginBottom: "10px",
    paddingLeft: "20px",
  },
  tableSection: {
    paddingLeft: "20px",
  },
  infoIcon: {
    color: "#3B5998",
    fontSize: "20px",
    width: "17px !important",
    height: "17px !important",
    color: "#2a2d32",
  },
  tableCellSection: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    // textAlign: "center",
    justifyContent: "flex-start",
  },
  editableTextField: {
    background: "#FFF !important",
    marginRight: "10px !important",
    width: "60% !important",
    width: "95%",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
    },
  },
  editIcon: {
    color: "#3B5998",
    fontSize: "15px",
    marginRight: "10px",
    width: "17px !important",
    height: "17px !important",
    color: "#2a2d32",
  },
  width50: {
    width: "50%",
  },
  width100: {
    width: "100%",
  },
  displayFlex: {
    display: "flex",
  },
  spaceBtwn: {
    justifyContent: "space-between",
  },
  center: {
    justifyContent: "center",
  },
  customScrollbar: {
    scrollbarWidth: "thin",
    scrollbarColor: "#B7B7B7 transparent",
    padding: 0,
    margin: 0,
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      position: "absolute",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "30px",
      backgroundColor: "#2a2d32",
      minHeight: "60px",
      minWidth: 4,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#adadad",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
    "& .MuiTableRow-root": {
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
      "&:hover": { background: "#D1E6FF" },
    },
  },
  closeIcon: {
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      color: "#000",
    },
  },
  dialogContentSection: {
    height: "441px !important",
    width: "600px !important",
    padding: "0px !important",
    // overflowY: "hidden !important",
  },
  dialogTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#1B1C1E",
  },

  icon: {
    width: "1.2rem",
    height: "1.2rem",
    marginRight: "10px",
  },
  submitTagsButton: {
    fontStyle: "Public Sans",
    width: 320,
    height: 43,
    color: "#FFF !important",
    padding: "12px 35px !important",
    background: "#2a2d32 !important",
    borderRadius: "38px !important",
    cursor: "pointer",
    border: "none",
  },
  tableBottomSection: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "20px",
  },
};

export default styles;
