import axios from "axios";
const AXIOS = axios.create({ baseURL: process.env.REACT_APP_API_SHORT_PATH });

AXIOS.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
); 

AXIOS.interceptors.response.use(
  (response) => response,
  (error) => {
    // // Reject promise if usual error
    // if (error.response.status !== 401) {
    //   return Promise.reject(error);
    // }
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default AXIOS;
