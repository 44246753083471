import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { getContentSupply, getLifetimeEngagement } from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";

const columns = [
  {
    id: "week",
    label: "Week",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "overall_day_old_shares",
    label: "Share",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "overall_day_old_download",
    label: "Download",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "overall_day_old_likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "overall_day_old_view",
    label: "View",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "one_day_old_shares",
    label: "Share",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "one_day_old_download",
    label: "Download",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "one_day_old_likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "one_day_old_view",
    label: "View",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "seven_day_old_shares",
    label: "Share",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "seven_day_old_download",
    label: "Download",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "seven_day_old_likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "seven_day_old_view",
    label: "View",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirty_day_old_shares",
    label: "Share",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirty_day_old_download",
    label: "Download",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "thirty_day_old_likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "thirty_day_old_view",
    label: "View",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirtyplus_day_old_shares",
    label: "Share",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "thirtyplus_day_old_download",
    label: "Download",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "thirtyplus_day_old_likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "thirtyplus_day_old_view",
    label: "View",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
 
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  week,
  overall_day_old_shares=0,
  overall_day_old_download=0,
  overall_day_old_likes=0,
  overall_day_old_view=0,
  one_day_old_shares=0,
  one_day_old_download=0,
  one_day_old_likes=0,
  one_day_old_view=0,
  seven_day_old_shares=0,
  seven_day_old_download=0,
  seven_day_old_likes=0,
  seven_day_old_view=0,
  thirty_day_old_shares=0,
  thirty_day_old_download=0,
  thirty_day_old_likes=0,
  thirty_day_old_view=0,
  thirtyplus_day_old_shares=0,
  thirtyplus_day_old_download=0,
  thirtyplus_day_old_likes=0,
  thirtyplus_day_old_view=0,
) {
  return {
    week,
    overall_day_old_shares,
    overall_day_old_download,
    overall_day_old_likes,
    overall_day_old_view,
  one_day_old_shares,
  one_day_old_download,
  one_day_old_likes,
  one_day_old_view,
  seven_day_old_shares,
  seven_day_old_download,
  seven_day_old_likes,
  seven_day_old_view,
  thirty_day_old_shares,
  thirty_day_old_download,
  thirty_day_old_likes,
  thirty_day_old_view,
  thirtyplus_day_old_shares,
  thirtyplus_day_old_download,
  thirtyplus_day_old_likes,
  thirtyplus_day_old_view,
  };
}

let rows = [];

export default function LifetimeEngagement() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [date, setDate] = useState([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getLifetimeEngagementData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getLifetimeEngagementData = async (
    date,
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getLifetimeEngagement(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
        let rowsTemp = [];
        data?.data?.forEach((tablerow, index) => {
          console.log(tablerow);
          rowsTemp.push(
            createData(
              formatReadableDate(tablerow.date_from) +
                " - " +
                formatReadableDate(tablerow.date_to),


                tablerow['overall_activity']?.share,
                tablerow['overall_activity']?.download,
                tablerow['overall_activity']?.like,
                tablerow['overall_activity']?.view,

                tablerow['1_day_old']?.share,
                tablerow['1_day_old']?.download,
                tablerow['1_day_old']?.like,
                tablerow['1_day_old']?.view,

              tablerow['7_days_old']?.share,
              tablerow['7_days_old']?.download,
              tablerow['7_days_old']?.like,
              tablerow['7_days_old']?.view,

              tablerow['7_30_days_old']?.share,
              tablerow['7_30_days_old']?.download,
              tablerow['7_30_days_old']?.like,
              tablerow['7_30_days_old']?.view,

              tablerow['30_days_old']?.share,
              tablerow['30_days_old']?.download,
              tablerow['30_days_old']?.like,
              tablerow['30_days_old']?.view,
              
             
              tablerow.generated_count,
            )
          );
        });
        setRows(rowsTemp);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getLifetimeEngagementData([moment().subtract(7, "d")._d, moment().subtract(1, "d")._d,]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return   rows.length === 0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
  
    <Box sx={{ paddingRight: "2rem" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Lifetime Engagement</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow sx={{ }}>
              <TableCell key={'1'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)'}}
                     />
              <TableCell key={'2'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={4}>
                Overall
              </TableCell>
              <TableCell key={'3'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={4}>
                1 Day Old
              </TableCell>
              <TableCell key={'4'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                    colSpan={4}>
                1-7 Day Old
              </TableCell>
              <TableCell key={'5'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', borderRight:'1px solid rgba(0, 0, 0, 0.15)' }}
                   colSpan={4}>
                7-30 Day Old
              </TableCell>
              <TableCell key={'6'} align="center" sx={{ backgroundColor: "rgba(209, 230, 255, 1)", borderBottom:'1px solid rgba(0, 0, 0, 0.15)', }}
                    colSpan={4}>
                30+ Day Old
              </TableCell>
            </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ backgroundColor: "rgba(209, 230, 255, 1)" , borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ borderRight: index===16?'none':'1px solid rgba(0, 0, 0, 0.15)'}}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
