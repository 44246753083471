import React, { useEffect, useContext, useState } from "react";

// MUI
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";

// packages
import moment from "moment";

// components
import PostStatusType from "components/postStatusType";
import { AuthContext } from "contexts/AuthContext";
import DateRangeInput from "components/input/DateRangeInput";
import TableComponent from "components/TableComponent";
import ButtonInput from "components/input/Button";

// contexts
import { AlertContext } from "contexts/AlertContext";

// styles
import styles from "./styles";

// internal component
import TableBodyComponent from "./components/TableBodyComponent";
import TableHeaderComponent from "./components/TableHeaderComponent";
import { getTagsCounts, getTagsList, verifyTags, editTagName } from "./actions";

// static data
export const columns = [
  { name: "tag_name", label: "Tag Name", minWidth: 270, isSortable: false },
  { name: "category", label: "Category", minWidth: 170, isSortable: false },
  {
    name: "tag_frequency",
    label: "Tag Frequency",
    minWidth: 170,
    align: "center",
    isSortable: true,
  },
  {
    name: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
    isSortable: false,
  },
];

const TagVerification = (props) => {
  const { classes } = props;

  const [tags, setTags] = useState([]);
  const [postCounts, setPostsCounts] = useState({
    Approved_count: 0,
    Pending_count: 0,
    Rejected_count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [postType, setPostType] = useState("Pending");
  const [date, setDate] = useState([moment().subtract(7, "d")._d, moment()._d]);
  const [data, setData] = useState({ approved: [], rejected: [] });
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [order, setOrder] = useState("asc");
  const [dataLoading, setDataLoading] = useState(false);

  const { setActivePage } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);

  const createData = (rowId, tag_name, category, tag_frequency, type) => {
    return {
      rowId,
      tag_name,
      category,
      tag_frequency,
      isApproved: type === "Pending" ? null : type === "Approved",
    };
  };

  const formatDate = (date) => {
    let startDate = moment(date[0]).format("YYYY-MM-DD");
    let endDate = moment(date[1]).format("YYYY-MM-DD");
    startDate = startDate + " 00:00:00";
    endDate = endDate + " 23:59:59";
    return { startDate, endDate };
  };

  const handleEdit = async (rowId, newTag) => {
    const { data } = await editTagName(rowId, newTag);
    const newTagsData = tags.map((tag) => {
      if (tag.rowId === data.tag_id) {
        tag.tag_name = newTag;
      }
      return tag;
    });
    setTags(newTagsData);
  };

  const updateCountsAndTagsList = async (
    type,
    date,
    limit = 10,
    offset = 0
  ) => {
    try {
      const { startDate, endDate } = formatDate(date);
      const { Approved, Rejected, Pending } = await getTagsCounts(
        startDate,
        endDate
      );
      setPostsCounts({
        Approved_count: Approved,
        Pending_count: Pending,
        Rejected_count: Rejected,
      });
      const { data, error } = await getTagsList(
        type,
        startDate,
        endDate,
        limit,
        offset
      );
      setFormattedTags(data, type);
    } catch (error) {
      if (error.message.includes("400")) {
        setTags([]);
      } else {
        showAlert("Something went wrong", "error");
      }
      setDataLoading(false);
      setLoading(false);
    }
  };

  const setFormattedTags = (data, type) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const formattedRowData = createData(
        data[i].tag.tag_id,
        data[i].tag.tag_name,
        data[i].tag.category_name,
        data[i].frequency,
        type
      );
      rows.push(formattedRowData);
    }
    setData({ approved: [], rejected: [] });
    setTags(rows);
  };
  const handleChangePage = async (event, newPage) => {
    setDataLoading(true);
    setPage(newPage);
    const newoffset = limit * newPage;
    setOffset(newoffset);
    const { startDate, endDate } = formatDate(date);
    const { data, error } = await getTagsList(
      postType,
      startDate,
      endDate,
      limit,
      newoffset
    );
    setFormattedTags(data, postType);
    setDataLoading(false);
  };

  const selectPostType = async (type) => {
    try {
      setDataLoading(true);
      setPostType(type);
      setPage(0);
      setOffset(0);
      await updateCountsAndTagsList(type, date);
      setDataLoading(false);
    } catch (error) {
      if (error.message.includes("400")) {
        setTags([]);
      } else {
        showAlert("Something went wrong", "error");
      }
      setDataLoading(false);
    }
  };

  const handleDateChange = async (value) => {
    try {
      setDataLoading(true);
      setDate(value);
      await updateCountsAndTagsList(postType, value);
      setDataLoading(false);
    } catch (error) {
      if (error.message.includes("400")) {
        setTags([]);
      } else {
        showAlert("Something went wrong", "error");
      }
      setDataLoading(false);
    }
  };

  const createSortHandler = (property) => (event) => {
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  const handleApprovedReject = (id, isApproved) => {
    if (isApproved === null) {
      setData(() => {
        return {
          approved: data.approved?.filter((d) => d !== id),
          rejected: data.rejected?.filter((d) => d !== id),
        };
      });
      return;
    }
    if (isApproved) {
      setData(() => {
        return {
          approved: [...data.approved, id],
          rejected: data.rejected?.filter((d) => d !== id),
        };
      });
    } else {
      setData(() => {
        return {
          approved: data.approved?.filter((d) => d !== id),
          rejected: [...data.rejected, id],
        };
      });
    }
  };

  const setDataOnLoad = async () => {
    try {
      setDataLoading(true);
      const approved = [];
      const rejected = [];
      setData({ approved, rejected });
      updateCountsAndTagsList(postType, date);
      setDataLoading(false);
    } catch (error) {
      if (error.message.includes("400")) {
        setTags([]);
      } else {
        showAlert("Something went wrong", "error");
      }
      setDataLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    setActivePage("Tags Verification");
    setDataOnLoad();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!data.rejected.length && !data.approved.length) {
        showAlert("Please select atleast one tag status", "error");
        return;
      }
      setLoading(true);
      if (postType === "Pending") {
        await verifyTags(data.rejected, data.approved);
      } else if (postType === "Approved") {
        await verifyTags(data.rejected, []);
      } else {
        await verifyTags([], data.approved);
      }
      updateCountsAndTagsList(postType, date);
      setLoading(false);
      showAlert("Tags submitted successfully", "success");
    } catch (error) {
      console.log("Error", error.message);
      if (error.message.includes("400")) {
        setTags([]);
      } else {
        showAlert("Something went wrong", "error");
      }
      setDataLoading(false);
      setLoading(false);
    }
  };

  return (
    <Box className={classes.tagVerificationSection}>
      <Backdrop
        sx={{ color: "#000", zIndex: 90000000000099 }}
        open={loading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
      <Box className={classes.verificationTop}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <PostStatusType
            postCounts={postCounts}
            postType={postType}
            selectPostType={selectPostType}
          />
        </Box>
        <Box>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />
        </Box>
      </Box>
      <Box className={classes.tableName}>Tag Verification</Box>
      <Box className={classes.tableSection}>
        <TableComponent
          pagination={true}
          limit={limit}
          maxCount={Number(postCounts[`${postType}_count`])}
          page={page}
          handleChangePage={handleChangePage}
          columns={columns}
          rows={tags}
          TableBodyComponent={TableBodyComponent}
          TableHeaderComponent={TableHeaderComponent}
          postType={postType}
          handleApprovedReject={handleApprovedReject}
          createSortHandler={createSortHandler}
          order={order}
          loading={dataLoading}
          handleEdit={handleEdit}
        />
      </Box>
      <Box className={classes.tableBottomSection}>
        <ButtonInput
          onClick={handleSubmit}
          disabled={loading}
          className={classes.submitTagsButton}
        >
          {loading ? <CircularProgress sx={{ color: "#FFF" }} /> : "Submit"}
        </ButtonInput>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(TagVerification);
