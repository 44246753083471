import React, { useEffect } from "react";

// packages
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// MUI
import { withStyles } from "@mui/styles";

// styles
const styles = {};

const AdminLayout = (props) => {
  const { classes } = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(location);
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default withStyles(styles)(AdminLayout);
