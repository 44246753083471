import React, { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AlertContext } from "contexts/AlertContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertBar = () => {
  const { msg, severity, hideAlert } = useContext(AlertContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    msg ? setOpen(true) : setOpen(false);
  }, [msg]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={hideAlert}
      autoHideDuration={5000}
      sx={{ zIndex: 9999999999999 }}
    >
      {msg && (
        <Alert onClose={hideAlert} severity={severity} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      )}
    </Snackbar>
  );
};

export default AlertBar;
