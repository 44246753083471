import React from "react";

// MUI
import { Pagination, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

// styles
import styles from "./styles";

const PaginationComp = (props) => {
  const { classes } = props;

  return (
    <Stack
      sx={{
        // marginTop: "14px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <KeyboardDoubleArrowLeftOutlinedIcon
        sx={{
          color: props.currentPage > 1 ? "#000000" : "#9E9E9E",
          cursor: "pointer",
          marginRight: "10px",
        }}
        onClick={() => {
          props.currentPage > 1 && props.handlePageChange(1);
        }}
      />
      <Pagination
        count={props.pageCount}
        shape="rounded"
        page={props.currentPage}
        onChange={(event, currPage) => props.handlePageChange(currPage)}
        sx={{
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 13,
          "& .Mui-selected": {
            background: "#2a2d32 !important",
            color: "#FFF",
          },
        }}
      />
      <KeyboardDoubleArrowRightOutlinedIcon
        sx={{
          color: props.currentPage < props.pageCount ? "#000000" : "#9E9E9E",
          cursor: "pointer",
          marginLeft: "10px",
        }}
        onClick={() => {
          props.currentPage < props.pageCount &&
            props.handlePageChange(props.pageCount);
        }}
      />
    </Stack>
  );
};

export default withStyles(styles)(PaginationComp);
