import React, { useContext, useState } from "react";
import { withStyles } from "@mui/styles";

// contexts
import { AuthContext } from "contexts/AuthContext";

// components
import SidebarOption from "./SidebarOption";

// styles
import styles from "./styles";

// MUI
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

// images
import LogoImage from "assets/images/Frame 157261.png";
import AccountImage from "assets/images/account_image.png";

import { Link } from "react-router-dom";

const Sidebar = (props) => { 
  const { classes } = props;
  const { activePage, user, setIsLoggedIn, setUser } = useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_state");
    setAnchorElUser(null);
    setIsLoggedIn(false);
    setUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "0.85rem !important",
    },
  })(Tooltip);
  return (
    <div className={classes.sidenav}>
      <Box>
        <Link to="/admin/tagging" className={classes.navbarTop}>
          <img src={LogoImage} className={classes.websiteLogo} />
        </Link>
        <div className={classes.sidebarOptionsSection}>
          {props.sidebarOptions.map((option, index) => {
            if (option.isProtected && user?.role !== "superAdmin") {
              return null;
            }
            return (
              <SidebarOption
                key={index}
                option={option}
                activePage={activePage}
              />
            );
          })}
        </div>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <CustomTooltip title="Click to Logout" placement="top" arrow>
          {/* <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0, width: "36px", height: "34px", margin: "0 auto" }}
        >
          <Avatar sx={{ width: "36px", height: "36px" }} src={AccountImage} />
        </IconButton> */}
          <LogoutIcon
            sx={{
              width: "1.3rem",
              height: "1.3rem",
              color: "#FFF",
              cursor: "pointer",
            }}
            onClick={handleLogout}
          />
        </CustomTooltip>
        <Typography sx={{ color: "#FFF", fontSize: "0.85rem" }}>
          {user?.name}
        </Typography>
      </Box>
      {/* <Menu
        sx={{ ml: "45px", mt: "10px", zIndex: 999999999999 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu> */}
    </div>
  );
};

export default withStyles(styles)(Sidebar);
