import { useContext, useEffect, useState } from "react";
import uuid from "react-uuid";
//import { FixedSizeList } from "react-window";
import dayjs from "dayjs";
//import Papa from "papaparse";

//mui
import { Container, styled } from "@mui/system";
import {
  Divider,
  TextField,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthContext } from "contexts/AuthContext";
import {
  getBucketListAPI,
  getStateListAPI,
  getUserCount,
  sendNotificationAPI,
  sendNotificationScheduledAPI,
} from "./actions";
import moment from "moment";
import { AlertContext } from "contexts/AlertContext";
import useLocalStorage from "customHooks/useLocalStorage";

//components
//import { ListboxComponent, StyledPopper } from "../virtulizedMuiList";
//import DateSelect from "../datePickerPopover";
//import RecuuModal from "../RecuurenceModal";

//constants
//import { STATE_LIST } from "../../constants/stateList";

//providers
//import { useSurveyData } from "../../providers/surveyContext";
//import { useAlert } from "../../providers/alertProvider";
//import { useAuth } from "../../providers/authProvider";

//custom style
const RootContainer = styled("form")({
  width: "100%",
  display: "flex",
  placeItems: "center",
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "0.6rem 0.7rem!important",
    outline: "none",
  },
});

const MultiLineTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    outline: "none",
  },
});

const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "0.6rem 0.7rem!important",
    outline: "none",
  },
});

const DayTextBox = styled(Box)({
  border: "1px solid grey",
  padding: "0.5rem",
  borderRadius: "5px",
  marginBottom: "1rem",
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  overflow: "hidden",
  textWrap: "nowrap",
  cursor: "pointer",
  "&:hover": {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: 7,
      height: 7,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(155, 155, 155, 0.5)",
      borderRadius: 20,
      border: "transparent",
    },
  },
});

// menuProps
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialNotificationValues = {
  title: "",
  body: "",
  image: "",
};
const initialScheduleValues = {
  value: "now",
  time: dayjs(new Date()),
  date: dayjs(new Date()),
  startDate: "Today",
  endDate: "No end date",
  day: [],
};

function lastMonth(date) {
  // Create a new Date object representing the last day of the month of the provided date
  return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
}

const initialScheduleValuesDates = {
  startDate: dayjs(lastMonth(new Date())),
  endDate: dayjs(new Date()),
};

const initialAdditionalValues = [
  {
    key: "",
    value: "",
    id: uuid(),
  },
];

const initialAdditionalNumbers = [
  {
    key: "mobile",
    value: "",
    id: uuid(),
  },
];

const initialTargetValues = {
  projects: [],
  surveys: [],
  fas: [],
};

const convertArrayObjectToObject = (arrayObject) => {
  let keyValue = {};

  arrayObject.forEach((value) => {
    if (value.key)
      keyValue = {
        ...keyValue,
        [value.key]: value.value,
      };
  });
  return keyValue;
};

// convert to milliseconds
const toMilliseconds = (hrs, min, sec) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

const datetoMilliseconds = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

export const ComposeNotification = () => {
  //context
  const {
    fetchSurveyList,
    fetchFa,
    submitNotification,
    surveyDispatch,
    //surveyData: {   },
    fetchProject,
  } = {};

  // const {
  //   authState: { userDetail },
  // } = {userDetail : {}};

  const [activeStep, setActiveStep] = useState(0);
  const [notificationValues, setNotificationValues] = useState(
    initialNotificationValues
  );
  const [scheduleValues, setScheduleValues] = useState(initialScheduleValues);
  const [additionalValues, setAdditionalValues] = useState(
    initialAdditionalValues
  );
  const [additionalNumbers, setAdditionalNumbers] = useState(
    initialAdditionalNumbers
  );
  const [isRecuurenceOpen, setIsRecuurenceOpen] = useState(false);
  const [targetValues, setTargetValues] = useState(initialTargetValues);
  const { user, setActivePage } = useContext(AuthContext);
  const [bucketList, setBucketList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateID] = useState(-1);
  const [bucketId, setBucketID] = useState(-1);
  const { showAlert } = useContext(AlertContext);

  const [userCount, setUserCount] = useState(0);

  const [startDate, setStartDate] = useState(
    dayjs(lastMonth(new Date())).format("YYYY-MM-DD") + "T00:00:00"
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD") + "T00:00:00"
  );

  const { getLocalStorage, setLocalStorage } = useLocalStorage();

  useEffect(() => {
    setActivePage("Notification");
    getBucketList();
    getStateList();
  }, []);

  const sendNotification = async (
    title,
    body,
    image,
    state_id,
    scheduling,
    bucket_id,
    start_date,
    end_date,
    additional_fields,
    numbers,
    userids
  ) => {
    try {
      let scheduled_time =
        scheduleValues.date.format("YYYY-MM-DD") +
        "T" +
        scheduleValues.time.format("HH:mm:ss");

      const { data, error } =
        scheduleValues.value === "scheduled"
          ? await sendNotificationScheduledAPI(
              title,
              body,
              image,
              state_id,
              scheduling,
              bucket_id,
              start_date,
              end_date,
              additional_fields,
              numbers,
              userids,
              scheduled_time
            )
          : await sendNotificationAPI(
              title,
              body,
              image,
              state_id,
              scheduling,
              bucket_id,
              start_date,
              end_date,
              additional_fields,
              numbers,
              userids
            );
      console.log(data);
      if (data === null) {
        console.log("Error", error.message);
        showAlert("Error in sending notification", "error");
      } else {
        showAlert("Notification sent successfully", "success");
        setAdditionalValues(initialAdditionalValues);
        setAdditionalNumbers(initialAdditionalNumbers);
        setNotificationValues(initialNotificationValues);
        setScheduleValues(initialScheduleValues);
        setBucketID(-1);
        setStateID(-1);
        setUserCount(0);
        setActiveStep(0);
      }
    } catch (error) {
      showAlert(error.message, "error");
      console.log("Error", error.message);
    }
  };

  const [userCountLoading, setUserCountLoading] = useState(false);

  const getBucketUserCount = async (startDate, endDate, stateId, bucketId) => {
    try {
      setUserCountLoading(true);
      const { data, error } = await getUserCount(
        startDate,
        endDate,
        stateId,
        bucketId
      );
      console.log(data);
      if (data === null) {
        setUserCount(0);
        setUserCountLoading(false);
      } else {
        setUserCount(data.user_count);
        setUserCountLoading(false);
      }
    } catch (error) {
      console.log("Error", error.message);
      setUserCountLoading(false);
    }
  };

  const getStateList = async () => {
    try {
      const { data, error } = await getStateListAPI();

      console.log(data);
      if (data === null) {
        setStateList([]);
      } else {
        setStateList(data.state);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const getBucketList = async () => {
    try {
      const { data, error } = await getBucketListAPI();

      console.log(data);
      if (data === null) {
        setBucketList([]);
      } else {
        setBucketList(data.notification_buckets);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e, value) => {
    e.stopPropagation();
    setActiveStep(value);
  };

  const handleNotificationChange = (event) => {
    const { value, name } = event.target;
    setNotificationValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleScheduleChange = (event) => {
    setScheduleValues((prev) => ({ ...prev, value: event.target.value }));
  };

  const handleDateTime = (newValue, variant) => {
    setScheduleValues((prev) => ({ ...prev, [variant]: newValue }));
  };

  const handleStartDate = (newValue) => {
    let date = dayjs(newValue).format("YYYY-MM-DD") + "T00:00:00";
    setStartDate(date);
  };

  const handleEndDate = (newValue) => {
    let date = dayjs(newValue).format("YYYY-MM-DD") + "T00:00:00";
    // getBucketUserCount(startDate, date, "", bucketId);
    setEndDate(date);
  };

  const handleAdditonalText = (e, id) => {
    const { name, value } = e.target;
    const newAdditonalValue = additionalValues.map((currentValue) => {
      if (id === currentValue.id) {
        currentValue = { ...currentValue, [name]: value };
      }
      return currentValue;
    });
    setAdditionalValues(newAdditonalValue);
  };

  const handleAddAddionalValue = (e) => {
    e.stopPropagation();
    setAdditionalValues((prev) => [
      ...prev,
      { ...initialAdditionalValues[0], id: uuid() },
    ]);
  };
  const handleDeleteAdditonalValue = (e, id) => {
    e.stopPropagation();
    const newAdditonalValue = additionalValues.filter(
      (value) => value.id !== id
    );
    setAdditionalValues(newAdditonalValue);
  };

  const handleAdditonalNumbers = (e, id) => {
    const { name, value } = e.target;
    const newAdditonalValue = additionalNumbers.map((currentValue) => {
      if (id === currentValue.id) {
        currentValue = { ...currentValue, [name]: value };
      }
      return currentValue;
    });
    setAdditionalNumbers(newAdditonalValue);
  };

  const handleAddAdditonalNumbers = (e) => {
    e.stopPropagation();
    setAdditionalNumbers((prev) => [
      ...prev,
      { ...initialAdditionalNumbers[0], id: uuid() },
    ]);
  };
  const handleDeleteAdditonalNumbers = (e, id) => {
    e.stopPropagation();
    const newAdditonalValue = additionalNumbers.filter(
      (value) => value.id !== id
    );
    setAdditionalNumbers(newAdditonalValue);
  };

  const handleSubmitNotification = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (bucketId === -1) {
      showAlert("Kindly fill all required fields", "error");
      return;
    }
    if (notificationValues.body.length === 0) {
      showAlert("Kindly fill all required fields", "error");
      return;
    }
    if (additionalValues[0].key === "") {
      showAlert("Kindly fill all required fields", "error");
      return;
    }
    let adv = {};
    additionalValues.map((value) => {
      adv[value.key] = value.value;
    });

    console.log(additionalNumbers);

    let numbers = [];
    let userIds = [];
    additionalNumbers.map((value) => {
      if (value.key === "mobile") {
        if (value.value !== "") {
          numbers.push(value.value);
        }
      } else {
        if (value.value !== "") {
          userIds.push(value.value);
        }
      }
    });

    if (!(bucketId === 1 || bucketId === 5)) {
      const eDate = dayjs(new Date()).format("YYYY-MM-DD") + "T00:00:00";
      const sDate = "2020-01-01T00:00:00";
      sendNotification(
        notificationValues.title,
        notificationValues.body,
        notificationValues.image,
        stateId,
        scheduleValues.value,
        bucketId,
        sDate,
        eDate,
        adv,
        numbers,
        userIds
      );
      return;
    }
    sendNotification(
      notificationValues.title,
      notificationValues.body,
      notificationValues.image,
      stateId,
      scheduleValues.value,
      bucketId,
      startDate,
      endDate,
      adv,
      numbers,
      userIds
    );
  };

  const generateCustomMessage = (dayArray) => {
    let str = `Every week on `;
    dayArray.forEach((day, i) => {
      i !== dayArray.length - 1
        ? i === dayArray.length - 2
          ? (str += day.day_string + " and ")
          : (str += day.day_string + ", ")
        : (str += day.day_string);
    });
    return str;
  };

  const onCloseCustom = () => {
    setIsRecuurenceOpen(false);
    if (scheduleValues?.day?.length === 0)
      setScheduleValues((prev) => ({ ...prev, value: "daily" }));
  };

  return (
    <RootContainer onSubmit={handleSubmitNotification}>
      <Container
        maxWidth="sm"
        sx={{
          background: "#fff",
          padding: "40px",
          paddingTop: "20px",
          boxShadow: "0px 3px 8px #00000029",
          borderRadius: "4px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ marginBottom: "20px" }} variant="h5">
          Compose Notification
        </Typography>
        <Divider />
        <Box sx={{ maxWidth: 400, marginTop: "20px", marginBottom: "20px" }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step onClick={(e) => handleBack(e, 0)}>
              <StepLabel
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,.04)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  },
                }}
              >
                Notification
              </StepLabel>
              <StepContent>
                <FormControl>
                  <Box mb={3}>
                    <Typography variant="caption" gutterBottom>
                      Notification Title (optional)
                    </Typography>
                    <CustomTextField
                      placeholder="Enter title"
                      type="text"
                      value={notificationValues.title}
                      name="title"
                      onChange={handleNotificationChange}
                      fullWidth
                      style={{ backgroundColor: "#fff" }}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography variant="caption" gutterBottom>
                      Notification Text
                    </Typography>
                    <MultiLineTextField
                      type="text"
                      name="body"
                      value={notificationValues.body}
                      onChange={handleNotificationChange}
                      placeholder="Enter text"
                      fullWidth
                      style={{ backgroundColor: "#fff" }}
                      multiline
                      maxRows={4}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography variant="caption" gutterBottom>
                      Notification Image (optional)
                    </Typography>
                    <CustomTextField
                      type="text"
                      name="image"
                      value={notificationValues.image}
                      onChange={handleNotificationChange}
                      placeholder="Enter image url"
                      fullWidth
                      style={{ backgroundColor: "#fff" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        disabled={!notificationValues.body.length}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Next
                      </Button>
                    </div>
                  </Box>
                </FormControl>
              </StepContent>
            </Step>
            <Step onClick={(e) => handleBack(e, 1)}>
              <StepLabel
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,.04)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  },
                }}
              >
                Target
              </StepLabel>
              <StepContent>
                <FormControl sx={{ m: 1, width: 350 }}>
                  <Typography variant="caption" gutterBottom>
                    Select Bucket
                  </Typography>

                  <Select
                    value={bucketId}
                    onChange={(e) => {
                      setBucketID(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled key={-1} value={-1}>
                      Select Bucket
                    </MenuItem>
                    {bucketList.map((bucket, index) => (
                      <MenuItem key={bucket.bucket_id} value={bucket.bucket_id}>
                        {bucket.bucket_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {bucketId !== -1 && (
                  <FormControl sx={{ m: 1, width: 350 }}>
                    <Typography variant="caption" gutterBottom>
                      Select State
                    </Typography>
                    <Select
                      value={stateId}
                      onChange={(e) => {
                        setStateID(e.target.value);
                      }}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {user?.role !== "superAdmin" && (
                        <MenuItem disabled key={-1} value={-1}>
                          Select State
                        </MenuItem>
                      )}
                      {user?.role === "superAdmin" && (
                        <MenuItem key={-1} value={-1}>
                          {"India"}
                        </MenuItem>
                      )}
                      {stateList.map((state, index) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {(bucketId === 1 || bucketId === 5) && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box mt={2} sx={{ display: "flex", gap: "1rem" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption" gutterBottom>
                          From Date
                        </Typography>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={initialScheduleValuesDates.startDate}
                          onChange={(newValue) => {
                            initialAdditionalValues.startDate = newValue;
                            handleStartDate(newValue);
                          }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption" gutterBottom>
                          To Date
                        </Typography>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={initialScheduleValuesDates.endDate}
                          disablePast
                          onChange={(newValue) => {
                            initialScheduleValuesDates.endDate = newValue;

                            handleEndDate(newValue);
                          }}
                        />
                      </Box>
                    </Box>
                  </LocalizationProvider>
                )}

                <Box>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (!(bucketId === 1 || bucketId === 5)) {
                          const eDate =
                            dayjs(new Date()).format("YYYY-MM-DD") +
                            "T00:00:00";
                          const sDate = "2020-01-01" + "T00:00:00";
                          if (stateId === -1) {
                            getBucketUserCount(sDate, eDate, "", bucketId);
                            return;
                          }
                          getBucketUserCount(sDate, eDate, stateId, bucketId);
                          return;
                        }
                        if (stateId === -1) {
                          getBucketUserCount(startDate, endDate, "", bucketId);
                          return;
                        }
                        getBucketUserCount(
                          startDate,
                          endDate,
                          stateId,
                          bucketId
                        );
                      }}
                      disabled={bucketId === -1}
                      sx={{ mt: 3, mr: 1 }}
                    >
                      Get User Count
                    </Button>
                  </div>
                </Box>

                <Typography sx={{ mt: 2, mr: 1 }} gutterBottom>
                  {userCountLoading
                    ? " Fetching user count..."
                    : "User Count: " + userCount}
                </Typography>

                <Typography></Typography>
                <Box sx={{ mb: 2, mt: 3 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={bucketId === -1 ? true : false}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step onClick={(e) => handleBack(e, 2)}>
              <StepLabel
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,.04)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  },
                }}
              >
                Scheduling
              </StepLabel>
              <StepContent>
                <FormControl fullWidth>
                  <CustomSelect
                    value={scheduleValues.value}
                    onChange={handleScheduleChange}
                    placeholder="select time"
                  >
                    <MenuItem value="now">Now</MenuItem>
                    <MenuItem value="scheduled">Schedule</MenuItem>
                  </CustomSelect>
                  {scheduleValues.value == "scheduled" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box mt={2} sx={{ display: "flex", gap: "1rem" }}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={scheduleValues.date}
                          disablePast
                          onChange={(newValue) =>
                            handleDateTime(newValue, "date")
                          }
                        />
                        <MobileTimePicker
                          value={scheduleValues.time}
                          onChange={(newValue) => {
                            handleDateTime(newValue, "time");
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  )}
                </FormControl>
                <Box sx={{ mb: 2, mt: 3 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step onClick={(e) => handleBack(e, 3)}>
              <StepLabel
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,.04)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  },
                }}
              >
                Additonal Option
              </StepLabel>
              <StepContent>
                <Box mb={3}>
                  <Typography variant="caption" gutterBottom>
                    Custom Data
                  </Typography>
                  {additionalValues.map((value, index) => (
                    <Box
                      key={value.id}
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        marginBottom: "10px",
                      }}
                    >
                      <CustomTextField
                        type="text"
                        placeholder="key"
                        name="key"
                        value={additionalValues[index].key}
                        onChange={(e) => handleAdditonalText(e, value.id)}
                        fullWidth
                        style={{ backgroundColor: "#fff" }}
                      />
                      <CustomTextField
                        type="text"
                        name="value"
                        value={additionalValues[index].value}
                        onChange={(e) => handleAdditonalText(e, value.id)}
                        fullWidth
                        style={{ backgroundColor: "#fff" }}
                      />
                      <IconButton
                        disabled={index === 0}
                        onClick={(e) => handleDeleteAdditonalValue(e, value.id)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <IconButton onClick={handleAddAddionalValue}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                <Box mb={3}>
                  <Typography variant="caption" gutterBottom>
                    Additional Numbers
                  </Typography>
                  {additionalNumbers.map((value, index) => (
                    <Box
                      key={value.id}
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        marginBottom: "10px",
                      }}
                    >
                      <Select
                        defaultValue={"mobile"}
                        size="small"
                        onChange={(e) => {
                          const newAdditonalValue = additionalNumbers.map(
                            (currentValue) => {
                              if (value.id === currentValue.id) {
                                currentValue.key = e.target.value;
                              }
                              return currentValue;
                            }
                          );
                          setAdditionalNumbers(newAdditonalValue);
                        }}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem key={"mobile"} value={"mobile"}>
                          Mobile
                        </MenuItem>
                        <MenuItem key={"user_id"} value={"user_id"}>
                          User Id
                        </MenuItem>
                      </Select>
                      {/* <CustomTextField
                        type="text"
                        placeholder="key"
                        name="key"
                        value={additionalValues[index].key}
                        onChange={(e) => handleAdditonalNumbers(e, value.id)}
                        fullWidth
                        style={{ backgroundColor: "#fff" }}
                      /> */}
                      <CustomTextField
                        type="text"
                        name="value"
                        value={additionalNumbers[index].value}
                        onChange={(e) => handleAdditonalNumbers(e, value.id)}
                        fullWidth
                        style={{ backgroundColor: "#fff" }}
                      />
                      <IconButton
                        disabled={index === 0}
                        onClick={(e) =>
                          handleDeleteAdditonalNumbers(e, value.id)
                        }
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <IconButton onClick={handleAddAdditonalNumbers}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 1, mr: 1 }}
          >
            Send Notification
          </Button>
        </Box>
      </Container>
    </RootContainer>
  );
};
