import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import { getContentSupply, getLifetimeEngagement, getUserwiseGrassrootReport, getUserwiseReport } from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";

const columns = [
  {
    id: "date",
    label: "Date",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state_name",
    label: "State Name",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "user_id",
    label: "User ID",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "phone",
    label: "Phone",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "name",
    label: "Name",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "is_active",
    label: "Active on app or not",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "avg_content",
    label: "Avg no of content consumed per user",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "acquisition",
    label: "Acquisition",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "views",
    label: "Views",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "likes",
    label: "Likes",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "shares",
    label: "Shares",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "downloads",
    label: "Downloads",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "gen_content",
    label: "No of Generated Content",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  date,
  state_name = 0,
  user_id = 0,
  phone = 0,
  name = 0,
  is_active = 0,
  avg_content = 0,
  acquisition = 0,
  views = 0,
  likes = 0,
  shares = 0,
  downloads = 0,
  gen_content = 0
) {
  return {
    date,
    state_name,
    user_id,
    phone,
    name,
    is_active,
    avg_content,
    acquisition,
    views,
    likes,
    shares,
    downloads,
    gen_content,
  };
}

//let rows = [];

export default function UserwiseReportGrassRoot() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(263);
  const [date, setDate] = useState([moment().subtract(1, "d")._d, moment().subtract(1, "d")._d,]);
  const [rows, setRows] = useState([]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getLifetimeEngagementData(value);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getLifetimeEngagementData = async (
    date,
    offset = 0,
    postType = "Pending"
  ) => {
    try {
      const formattedDate = formatDate(date);
      const { data, error } = await getUserwiseGrassrootReport(
        formattedDate.startDate,
        formattedDate.endDate
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
        let rowsTemp = [];
        data?.data?.forEach((tablerow, index) => {
          console.log(tablerow);
          rowsTemp.push(
            createData(
              formatReadableDate(formattedDate.startDate),
           
              tablerow.state,
              tablerow.user_id,
              tablerow.mobile,
              tablerow.name,
              tablerow.is_active === 1?'Yes':'No',
              tablerow.activity.view,
              tablerow.utm === null?'Organic': tablerow.utm,
              tablerow.activity.view,
              tablerow.activity.like,
              tablerow.activity.share,
              tablerow.activity.download,
              tablerow.generated_videos
            )
          );
        });
        setRows(rowsTemp);
      }
      //  setAdminLoading(false);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (date.length) {
      //   getTagCatagories();
      // getCounts(date);
      getLifetimeEngagementData([moment().subtract(1, "d")._d, moment().subtract(1, "d")._d,]);
    }
    //  selectActivePage("Content Tagging");
  }, []);

  return  rows.length === 0 ?<div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>  : (
  
    <Box sx={{ paddingRight: "2rem", height:'50%' }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>User-wise Engagement</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
                  <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "1rem",
          border: "1px solid rgba(209, 230, 255, 1)",
          "&.MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{}}>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)'
                  }}
                />
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)'
                  }}
                  colSpan={7}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)'
                  }}
                  colSpan={4}
                >
                  Engagement (Normal + Generated){" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "rgba(209, 230, 255, 1)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.15)",borderBottom:'1px solid rgba(0, 0, 0, 0.15)'
                  }}
                  colSpan={1}
                ></TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{
                      backgroundColor: "rgba(209, 230, 255, 1)",
                      borderRight:
                        index === 16
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.15)",
                    }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderRight:
                                index === 16
                                  ? "none"
                                  : "1px solid rgba(0, 0, 0, 0.15)",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
