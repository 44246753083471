// packages
import AXIOS from "services/axios";

// utils
import { getConfig } from "utils/config";

export const handleLogin = async (email, password) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(`v1/admin/login`, {
      email,
      password,
    });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getUserState = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/states/list/`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getAdminData = async (id) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/admins/${id}`);
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};
