import { Edit } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import './EditStyle.css'
import { AlertContext } from "contexts/AlertContext";
import { editCaptionFeatured } from "./actions";

const SelectLang = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [langId, setLangID] = useState(-1);
    const [langName, setLangName] = useState(props.value);
    const [langList, setLangList] = useState(props.langList
    );

    const { showAlert } = useContext(AlertContext);
    const handleLangUpdate = async (lang_id) => {
      const { data, error } = await editCaptionFeatured(props.post_id, props.is_active, undefined, undefined, lang_id);
      if(data){
        showAlert("Updated Sucessfully!", "success");
        for( let i = 0; i< langList.length; i++){
          if(langList[i].id === lang_id){
            setLangName(langList[i].language)
          }
      }
      }
      if(error){
        showAlert("Something Went Wrong!", "error");
      }
      setEditMode(false);
    };
  
    useEffect(() => {}, [editMode]);
  return (
    <>
     {!editMode ? (
                <div
                    className="language-display"
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    {langName}
                    <Edit className="edit-icon" />
                </div>
            ): (
      <FormControl>
           <InputLabel>Language</InputLabel>
      <Select
        value={langId}
        onChange={(e) => {
          setLangID(e.target.value);
          handleLangUpdate(e.target.value);
        }}
        label="Language"
        
      >
        <MenuItem key={-1} value={-1}>
          Select Language
        </MenuItem>
        {langList.map((lang, index) => {
           if (lang.is_active) {
            return (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.language}
              </MenuItem>
            );
        }
        })}
      </Select>
      </FormControl>
    )}
  </>
  )
}

export default SelectLang
