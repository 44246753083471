import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangeInput from "components/input/DateRangeInput";
import { useState } from "react";
import moment from "moment";
import {
  downloadStatewiseUserReport,
  getAdminListAPI,
  getContentSupply,
  getLifetimeEngagement,
  getStateListAPI,
  getStatewiseUserReport,
  getTaggingStatusReport,
  getUserwiseGrassrootReport,
  getUserwiseReport,
} from "../actions";
import { useEffect } from "react";
import Loading from "../loader/LoadingAnim";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const columns = [
  {
    id: "sno",
    label: "S No",
    minWidth: 100,
    //format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "page_id",
    label: "Page ID",
    minWidth: 100,
    //format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "page_name",
    label: "Page Name",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state",
    label: "State",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "language",
    label: "Language",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total_count",
    label: "Total Count",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "approved_count",
    label: "Approved",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "rejected_count",
    label: "Rejected",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "pending_count",
    label: "Pending",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const formatDate = (date) => {
  let startDate = moment(date[0]).format("YYYY-MM-DD");
  let endDate = moment(date[1]).format("YYYY-MM-DD");
  startDate = startDate + " 00:00:00";
  endDate = endDate + " 23:59:59";
  return { startDate, endDate };
};

const formatReadableDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function createData(
  sno,
  page_id,
  page_name,
  state,
  language,
  total_count,
  approved_count,
  rejected_count,
  pending_count
) {
  return {
    sno,
    page_id,
    page_name,
    state,
    language,
    total_count,
    approved_count,
    rejected_count,
    pending_count
  };
}

//let rows = [];

export default function TaggingStatus() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [date, setDate] = useState([
    moment().subtract(1, "d")._d,
    moment().subtract(1, "d")._d,
  ]);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1500);
  const [adminList, setAdminList] = useState([]);
  const [stateId, setStateID] = useState(1);
  const [loading, setIsLoading] = useState(false);

  const [rowsPerPagePrev, setRowsPerPagePrev] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //  if( rowsPerPage*newPage > totalPages ){
    //   setRowsPerPagePrev(rowsPerPage)
    //   setRowsPerPage(rowsPerPage*newPage - totalPages )
    //  }
    getTaggingData(date, stateId, rowsPerPage, rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getTaggingData(date, stateId, event.target.value, 0);
  };

  const handleDateChange = async (value) => {
    try {
      setRows([]);
      console.log(formatDate(value));
      setDate(value);
      getTaggingData(value, stateId, rowsPerPage, rowsPerPage * page);
    } catch (error) {
      if (error.message.includes("400")) {
      } else {
        alert("Something went wrong", "error");
      }
    }
  };

  const getAdminList = async () => {
    try {
      const { data, error } = await getAdminListAPI();

      console.log(data);
      if (data === null) {
        setAdminList([]);
      } else {
        setAdminList(data);
        setStateID(data[0].id);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const downloadCSV = async (date, state_id) => {
    try {
      // setIsLoading(true);
      const formattedDate = formatDate(date);
      const { data, error } = await downloadStatewiseUserReport(
        formattedDate.startDate,
        formattedDate.endDate,
        state_id
      );
      //  console.log(data);
    } catch (error) {
      console.log("Error", error.message);
      // setIsLoading(false)
    }
  };

  const getTaggingData = async (date, state_id, limit, offset) => {
    try {
      setIsLoading(true);
      const formattedDate = formatDate(date);
      const { data, error } = await getTaggingStatusReport(
        formattedDate.startDate,
        formattedDate.endDate,
        state_id,
        limit,
        offset
      );
      console.log(data);
      if (data === null) {
        setRows([]);
      } else {
        let rowData = [];
        data?.result?.forEach((tablerow, index) => {
          //  console.log(tablerow);
          if (index !== data?.result?.length - 1) {
            rowData.push(
              createData(
                index >= data?.result?.length - 2 ? null : index + 1,
                tablerow.page_id,
                index === data?.result?.length - 2
                  ? "Total"
                  : tablerow.page_name,
                tablerow.state,
                tablerow.language,
                tablerow.total_count,
                tablerow.approved_count,
                tablerow.rejected_count,
                tablerow.pending_count
              )
            );
          }
        });

        setRowsPerPage(rowData.length);
        setRows(rowData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (date.length) {
      getAdminList().then(()=>{  getTaggingData(
        [moment().subtract(1, "d")._d, moment().subtract(1, "d")._d],
        stateId,
        rowsPerPage,
        rowsPerPage * page
      );});
    
    }
  }, []);

  return (
    <Box sx={{ paddingRight: "2rem", height: "50%" }}>
      <Box
        sx={{
          fontSize: "21px",
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Tagging Status</Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <DateRangeInput date={date} handleDateChange={handleDateChange} />

          <Button
            onClick={() => {
              downloadCSV(date, stateId);
            }}
            sx={{ backgroundColor: "rgba(59, 89, 152, 1)" }}
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <FormControl
        sx={{ width: "10rem", marginBottom: "0.5rem", marginTop: "1.5rem" }}
      >
        <InputLabel>Admin</InputLabel>
        <Select
          value={stateId}
          onChange={(e) => {
            setStateID(e.target.value);
            getTaggingData(
              date,
              e.target.value,
              rowsPerPage,
              rowsPerPage * page
            );
          }}
          label="Admin"
        >
          {adminList.map((state, index) => (
            <MenuItem key={state.id} value={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {loading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: "1rem",
            border: "1px solid rgba(209, 230, 255, 1)",
            "&.MuiPaper-root": {
              boxShadow: "none",
            },
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <div id="content_supply">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        sx={{
                          backgroundColor: "rgba(209, 230, 255, 1)",
                          borderRight:
                            index === 8
                              ? "none"
                              : "1px solid rgba(0, 0, 0, 0.15)",
                        }}
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                  borderRight:
                                    index === 8
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}
